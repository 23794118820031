import i18n from "i18next";
import axios, { AxiosInstance, AxiosResponse, HttpStatusCode, InternalAxiosRequestConfig } from "axios";
import { BaseApiResponse } from "@edutrackr/shared/types";
import { ApiError, HttpError } from "../errors";
import { HttpClientConfig, HttpInterceptorConfig, HttpInterceptorManager } from "../types";
import { HttpConstants, I18nCommonKeys } from "@edutrackr/shared/constants";

type HttpRequestInterceptorConfig = HttpInterceptorConfig<InternalAxiosRequestConfig>;
type HttpResponseInterceptorConfig = HttpInterceptorConfig<AxiosResponse>;

export class HttpClient {

  private readonly config: HttpClientConfig;
  private readonly baseClient: AxiosInstance;

  constructor(config: HttpClientConfig) {
    this.config = config;
    this.baseClient = axios.create({
      baseURL: this.config.baseUrl,
      headers: {
        'Content-Type': 'application/json', // Only sent if the request has a body
      },
    });
  }

  public async get<T>(url: string, params?: unknown): Promise<T> {
    try {
      const response = await this.baseClient.get<T>(url, {
        params: params,
        headers: this.getHeaders(),
      });
      return this.handleSuccess(response);
    } catch (error) {
      const parsedError = this.handleError(error);
      throw parsedError;
    }
  }

  public async post<T>(url: string, data?: unknown): Promise<T> {
    try {
      const response = await this.baseClient.post<T>(url, data, {
        headers: this.getHeaders(),
      });
      return this.handleSuccess(response);
    } catch (error) {
      const parsedError = this.handleError(error);
      throw parsedError;
    }
  }

  public async put<T>(url: string, data?: unknown): Promise<T> {
    try {
      const response = await this.baseClient.put<T>(url, data, {
        headers: this.getHeaders(),
      });
      return this.handleSuccess(response);
    } catch (error) {
      const parsedError = this.handleError(error);
      throw parsedError;
    }
  }

  public async delete<T>(url: string, data?: unknown): Promise<T> {
    try {
      const response = await this.baseClient.delete<T>(url, {
        headers: this.getHeaders(),
        data: data,
      });
      return this.handleSuccess(response);
    } catch (error) {
      const parsedError = this.handleError(error);
      throw parsedError;
    }
  }

  /**
   * Sends an object as form data.
   */
  public async formData<T>(url: string, data: unknown): Promise<T> {
    try {
      const response = await this.baseClient.post<T>(url, data, {
        headers: {
          ...this.getHeaders(),
          'Content-Type': 'multipart/form-data',
        }
      });
      return this.handleSuccess(response);
    } catch (error) {
      const parsedError = this.handleError(error);
      throw parsedError;
    }
  }

  /**
   * Adds a request interceptor to the client.
   * @param config The interceptor configuration.
   * @returns An interceptor manager to clear the interceptor.
   */
  public addRequestInterceptor(config: HttpRequestInterceptorConfig): HttpInterceptorManager {
    const interceptorId = this.baseClient.interceptors.request.use(config.onFulfilled, config.onRejected);
    return {
      clear: () => this.baseClient.interceptors.request.eject(interceptorId),
    };
  }

  /**
   * Adds a response interceptor to the client.
   * @param config The interceptor configuration.
   * @returns An interceptor manager to clear the interceptor.
   */
  public addResponseInterceptor(config: HttpResponseInterceptorConfig): HttpInterceptorManager {
    const interceptorId = this.baseClient.interceptors.response.use(config.onFulfilled, config.onRejected);
    return {
      clear: () => this.baseClient.interceptors.response.eject(interceptorId),
    };
  }

  /**
   * Clears all request and response interceptors.
   */
  public clearInterceptors() {
    this.baseClient.interceptors.request.clear();
    this.baseClient.interceptors.response.clear();
  }

  private getHeaders(): Record<string, string> {
    const headers: Record<string, string> = {};
    if (this.config.headers) {
      Object.assign(headers, this.config.headers);
    }
    const authorizationToken = this.getAuthorizationToken();
    if (authorizationToken) {
      headers.Authorization = `Bearer ${authorizationToken}`;
    }
    const language = this.getLanguage();
    if (language) {
      headers[HttpConstants.headers.ACCEPT_LANGUAGE] = language;
    }
    return headers;
  }

  private getAuthorizationToken(): string | null {
    if (!this.config.authorizationToken) {
      return null;
    }
    if (typeof this.config.authorizationToken === 'function') {
      return this.config.authorizationToken();
    }
    return this.config.authorizationToken ?? null;
  }

  private getLanguage(): string | null {
    if (!this.config.language) {
      return null;
    }
    if (typeof this.config.language === 'function') {
      return this.config.language();
    }
    return this.config.language;
  }

  private handleSuccess<T>(response: AxiosResponse<T>) {
    return response.data;
  }

  private handleError(error: unknown) {
    const parsedError = this.parseError(error);
    this.delegateError(parsedError);
    return parsedError;
  }

  private parseError(error: unknown) {
    if (!axios.isAxiosError(error)) {
      return error;
    }
    const errorData = error.response?.data as BaseApiResponse<unknown>;
    if (errorData) {
      return new ApiError({
        message: errorData.message,
        status: errorData.statusCode,
        data: errorData.data,
        errorData: errorData.error,
        originalError: error,
      });
    }
    return new HttpError({
      message: i18n.t(I18nCommonKeys.messages.unknownError),
      status: error.response?.status ?? HttpStatusCode.InternalServerError,
      data: error.response?.data,
      originalError: error,
    });
  }

  private delegateError(error: unknown) {
    if (this.config.onError) {
      this.config.onError(error);
    }
  }

}
