import { Outlet } from 'react-router-dom';
import { MainContentContainer } from '@edutrackr/ui/layouts';
import { StudentBaseLayout } from './student-base-layout';

export function StudentMainLayout() {
  return (
    <StudentBaseLayout>
      <MainContentContainer>
        <Outlet />
      </MainContentContainer>
    </StudentBaseLayout>
  );
}

export default StudentMainLayout;
