import { RouterProvider, RouterProviderProps } from "react-router-dom";

export interface AppRouterProviderProps extends RouterProviderProps { }

export function AppRouterProvider(props: AppRouterProviderProps) {
  return (
    <RouterProvider router={props.router} />
  );
}

export default AppRouterProvider;
