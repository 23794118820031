import { AuthStatus } from "@edutrackr/shared/enums";
import { useTranslation } from "react-i18next";
import { useAuth } from "./use-auth";

/**
 * Hook to check if the app is ready.
 */
export const useAppReady = () => {
  const { authStatus } = useAuth();
  const authReady = authStatus !== AuthStatus.Pending;

  const { ready: i18nReady } = useTranslation();

  return i18nReady && authReady;
};
