export class ConfigError extends Error {
  constructor(message: string) {
      super(message);
      this.name = 'ConfigError';
  }
}

export const throwError = (message: string) => {
  throw new ConfigError(message);
}

export const validateEnumValue = <T extends object>(enumObject: T, value: string, message: string) => {
  const isValid = Object.values(enumObject).includes(value);
  if (!isValid) {
    throwError(`${message} (allowed values: ${Object.values(enumObject).join(', ')})`)
  }
  return isValid;
}
