import { toast } from "sonner";
import { useTranslation } from 'react-i18next';
import { useMutation } from "@tanstack/react-query";
import { ErrorUtils } from "@edutrackr/shared/utils";
import { TeacherMutationKeys } from "@edutrackr/shared/enums";
import { commonAssistantService } from "../../../shared/services";
import { GetLastSessionChatContentRequest } from "@edutrackr/services/common";

export const useStartNewSessionChatMutation = () => {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'hooks.useStartNewSessionChatMutation',
  });
  const cleanUserChatSessionMutation = useMutation({
    mutationKey: [TeacherMutationKeys.CleanUserChatSession as const],
    mutationFn: async (request: GetLastSessionChatContentRequest) => {
      return await commonAssistantService.startNewSessionChat(request);
    },
    onSuccess: data => {
      // toast.success(data.message);
    },
    onError: e => {
      const errorDetail = ErrorUtils.getErrorDetail(e);
      toast.error(t('error.title'), {
        description: errorDetail.message
      });
    },
  });

  return cleanUserChatSessionMutation;
}
