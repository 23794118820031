import { BaseLayout } from '../base-layout';

export interface MainLayoutProps {
  children: React.ReactNode;
}

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <BaseLayout>
      {children}
    </BaseLayout>
  );
}

export default MainLayout;
