import { useTranslation } from "react-i18next";
import { Icons } from "@edutrackr/ui/components";

interface ChatUnavailableOverlayProps {}

export function ChatUnavailableOverlay(props: ChatUnavailableOverlayProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-grow justify-center items-center gap-3 px-4 py-3">
      <Icons.BotOff className="w-12 h-12 text-muted-foreground pb-2" />
      <p className="text-center text-muted-foreground text-sm text-balance">
        {t("components.chat.overlays.unavailable.message")}
      </p>
    </div>
  );
}
