import { AppConstants } from "@edutrackr/shared/constants";

/**
 * App indicator to show the current version of the app.
 */
export function AppVersionIndicator() {
  return (
    <div className="fixed bottom-0.5 left-1 z-50 text-2xs text-gray-500">
      v{AppConstants.CURRENT_VERSION}
    </div>
  );
}
