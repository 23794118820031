export enum CommonQueryKeys {
  GetSessionById = 'getSessionById',
  GetUserSessions = 'getUserSessions',
  GetAllRoles = 'getAllRoles',
  GetAllCategories = 'getAllCategories',
}

export enum AdminQueryKeys {
  GetAllUsers = 'getUsers',
  GetAllCourses = 'getAllCourses',
  GetCourseById = 'getCourseById',
  GetCourseUsers = 'getCourseUsers',
}

export enum TeacherQueryKeys {
  GetUserCoursesForSessions = 'getUserCoursesForSessions',
  GetCourseResources = 'getCourseResources',
  GetSessionStatistics = 'getSessionStatistics',
  GetUserCoursesForCourseResources = 'getUserCoursesForCourseResources',
  GetCourseSessionsAttendanceMetrics = 'getCourseSessionsAttendanceMetrics',
  GetSessionPlan = 'getSessionPlan',
  GetLastSessionChat = 'getLastSessionChat',
}

export enum CommonMutationKeys {
  Login = 'login',
  RefreshSession = 'refreshSession',
  SessionConnect = 'sessionConnect',
  SessionDisconnect = 'sessionDisconnect',
  UpdateStudentPresence = 'updateStudentPresence',
}

export enum AdminMutationKeys {
  AddUser = 'addUser',
  EditUser = 'editUser',
  DeleteUser = 'deleteUser',
  AssignRole = 'assignRole',
  AddCourse = 'addCourse',
  EditCourse = 'editCourse',
  DeleteCourse = 'deleteCourse',
  AddCourseUser = 'addCourseUser',
  RemoveCourseUser = 'removeCourseUser',
  AddCategory = 'addCategory',
  EditCategory = 'editCategory',
}

export enum TeacherMutationKeys {
  AddSession = 'addSession',
  AssignCategoryToSession = 'assignCategoryToSession',
  RemoveCategoryFromSession = 'removeCategoryFromSession',
  EditSession = 'editSession',
  StartSession = 'startSession',
  EndSession = 'endSession',
  DeleteCourseResource = 'deleteCourseResource',
  GenerateSessionPlan = 'generateSessionPlan',
  UpdateSessionPlan = 'updateSessionPlan',
  GetAssistantAnswer = 'getAssistantAnswer',
  CleanUserChatSession = 'cleanUserChatSession',
}

export enum StudentMutationKeys {
  UploadVideo = 'uploadVideo',
  UploadMetrics = 'uploadMetrics',
}
