import { BaseService, HttpClient } from "@edutrackr/shared/utils";
import { BaseApiResponse } from "@edutrackr/shared/types";
import { GetAllRolesResponse } from "./types/role";

export class RoleService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getAllRoles(): Promise<BaseApiResponse<GetAllRolesResponse>> {
    return this.httpClient.get<BaseApiResponse<GetAllRolesResponse>>('/role/getAll');
  }

}
