import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const NonMemoizedMarkdownRenderer = ({ children }: { children: string }) => {
  const components: Partial<Components> = {
    code: ({ node, className, children, ...props }) => {
      const match = /language-(\w+)/.exec(className || "");
      return match ? (
        <SyntaxHighlighter
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          {...props as any}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={match[1]}
          className={`${className} text-sm w-[80dvw] md:max-w-[500px] overflow-x-scroll p-2 rounded mt-2 bg-zinc-800`}
          style={oneDark}
        >
          <code className={match[1]}>{children}</code>
        </SyntaxHighlighter>
      ) : (
        <code
          className={`${className} text-sm text-zinc-200 bg-zinc-800 py-0.5 px-1 rounded`}
          {...props}
        >
          {children}
        </code>
      );
    },
    ol: ({ node, children, ...props }) => {
      return (
        <ol className="list-decimal list-outside ml-4" {...props}>
          {children}
        </ol>
      );
    },
    li: ({ node, children, ...props }) => {
      return (
        <li className="py-1" {...props}>
          {children}
        </li>
      );
    },
    ul: ({ node, children, ...props }) => {
      return (
        <ul className="list-decimal list-outside ml-4" {...props}>
          {children}
        </ul>
      );
    },
    strong: ({ node, children, ...props }) => {
      return (
        <span className="font-semibold" {...props}>
          {children}
        </span>
      );
    },
    a: ({ node, children, ...props }) => {
      return (
        <a
          className="text-blue-500 hover:underline"
          target="_blank"
          rel="noreferrer"
          {...props}
        >
          {children}
        </a>
      );
    },
  };

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
      {children}
    </ReactMarkdown>
  );
};

export const MarkdownRenderer = React.memo(
  NonMemoizedMarkdownRenderer,
  (prevProps, nextProps) => prevProps.children === nextProps.children,
);
