import { NavLink } from "react-router-dom";
import { NavItem } from "@edutrackr/shared/types";
import { cn } from "@edutrackr/shared/utils"


export interface TopNavbarItemProps {
  variant?: 'default' | 'mobile';
  item: NavItem;
}

export function TopNavbarItem(props: TopNavbarItemProps) {

  const linkClassName = (isActive: boolean) => cn(
    isActive
      ? 'border-primary text-primary'
      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
  );

  const mobileLinkClassName = (isActive: boolean) => cn(
    isActive
      ? 'text-primary'
      : 'text-gray-600 hover:text-gray-800',
    'block pl-3 pr-4 py-2 font-medium text-base rounded-md hover:bg-gray-100'
  );

  return (
    <NavLink
      key={props.item.label}
      to={props.item.path}
      className={
        ({ isActive }) => props.variant === 'mobile'
          ? mobileLinkClassName(isActive)
          : linkClassName(isActive)
      }
    >
      <span className='flex items-center gap-2'>
        {props.item.icon}
        {props.item.label}
      </span>
    </NavLink>
  );
}

export default TopNavbarItem;
