import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import { DefaultQueryConfig, EnvConfig } from '@edutrackr/shared/config';
import { AppAuthProvider, AppQueryProvider } from '@edutrackr/shared/providers';
import { SentryClient } from '@edutrackr/shared/utils';
import { AppConstants } from '@edutrackr/shared/constants';
import App from './app/app';
import { httpClient, refreshSessionHandler } from './app/shared/services';
import './app/shared/i18n';

const sentryClient = new SentryClient({
  dsn: EnvConfig.sentry.STUDENT_DSN,
  release: AppConstants.CURRENT_VERSION,
  environment: EnvConfig.ENVIRONMENT,
  apiUrl: EnvConfig.API_URL,
});
sentryClient.init();

const queryClient = new QueryClient(DefaultQueryConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <AppQueryProvider client={queryClient}>
      <AppAuthProvider httpClient={httpClient} onRefresh={refreshSessionHandler}>
        <App />
      </AppAuthProvider>
    </AppQueryProvider>
  </StrictMode>
);
