import { BaseRealtimeService, RealtimeClientConfig } from "@edutrackr/shared/utils";

export class RealtimeSessionService extends BaseRealtimeService {
  constructor(config: RealtimeClientConfig) {
    super("session", config);
  }

  public async connect(): Promise<void> {
    await this.realtimeClient.start();
  }

  public async disconnect(): Promise<void> {
    await this.realtimeClient.stop();
  }

  /**
   * Adds a user to a session.
   */
  public addUserToSession(sessionId: string): Promise<void> {
    return this.realtimeClient.call("AddUserToSession", sessionId);
  }

  /**
   * Handles the session ending event.
   * @returns A function to unsubscribe from the event.
   */
  public onCloseSession(callback: (endSessionMessage: string) => void): () => void {
    this.realtimeClient.on("CloseSession", callback);
    return () => this.realtimeClient.off("CloseSession", callback);
  }

  /**
   * Handles session closing event.
   */
  public onConnectionClosed(callback: (error?: Error) => void): void {
    this.realtimeClient.onConnectionClose(callback);
  }

  /**
   * Handles session reconnecting event.
   */
  public onConnectionReconnected(callback: (connectionId?: string) => void): void {
    this.realtimeClient.onConnectionReconnected(callback);
  }

  /**
   * Updates the student presence state.
   * @returns A function to unsubscribe from the event.
   */
  public updateStudentPresence(isPresent: boolean): Promise<void> {
    return this.realtimeClient.call("UpdateStudentPresence", isPresent);
  }

  /**
   * Handles the student presence changed event.
   * @returns A function to unsubscribe from the event.
   */
  public onUserPresenceChanged(callback: (userId: string, isPresent: boolean) => void): () => void {
    this.realtimeClient.on("UpdateStudentPresence", callback);
    return () => this.realtimeClient.off("UpdateStudentPresence", callback);
  }

}
