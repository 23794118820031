import { useCallback, useEffect, useRef } from "react";
import i18n from "i18next";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { AuthRefreshParams } from "@edutrackr/shared/types";
import { CommonMutationKeys } from "@edutrackr/shared/enums";
import { I18nCommonKeys } from "@edutrackr/shared/constants";


interface UseRefreshSessionProps {
  /** Initial refresh token. */
  initialRefreshToken: string | null;

  /** Refresh session callback. */
  onRefresh: (refreshToken: string) => Promise<AuthRefreshParams>;

  /** Called when refresh is successful. */
  onSuccess: (data: AuthRefreshParams) => void;

  /** Called when refresh fails. */
  onError: () => void;
}

/**
 * Refresh session handler.
 */
export const useRefreshSession = ({
  initialRefreshToken,
  onRefresh,
  onSuccess,
  onError,
}: UseRefreshSessionProps) => {
  const isInitialRender = useRef(true);
  const isRefreshing = useRef(false);

  const refreshSessionMutation = useMutation({
    mutationKey: [CommonMutationKeys.RefreshSession],
    mutationFn: onRefresh,
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: () => {
      toast.error(i18n.t(I18nCommonKeys.hooks.useRefreshSession.error.title), {
        description: i18n.t(I18nCommonKeys.hooks.useRefreshSession.error.message),
      });
      onError();
    },
    onSettled: () => {
      isRefreshing.current = false;
    },
  });

  const refreshSession = useCallback((refreshToken: string | null) => {
    if (isRefreshing.current) {
      return;
    }
    if (refreshToken) {
      isRefreshing.current = true;
      refreshSessionMutation.mutate(refreshToken);
    }
  }, [refreshSessionMutation]);

  // Run initial refresh
  useEffect(() => {
    if (!isInitialRender.current) {
      return;
    }
    isInitialRender.current = false;
    if (!initialRefreshToken) {
      return onError();
    }
    return refreshSession(initialRefreshToken);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return refreshSession;
}
