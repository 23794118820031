import { AppLoader } from "../app-loader";
import { AppToaster } from "../app-toaster";
import { AppVersionIndicator } from "../app-version-indicator";
import { TailwindIndicator } from "../tailwind-indicator";

interface AppRootProps {
  /** Whether the app is ready */
  loading: boolean;

  /** Children component */
  children: React.ReactNode;
}

/**
 * Component that wraps the app and shows a loader while the app is loading.
 * It includes the app toaster and tailwind indicator.
 */
const AppRoot = ({ loading, children }: AppRootProps) => {
  return (
    <>
      {
        loading
          ? <AppLoader className="h-screen bg-gray-50" />
          : children
      }
      <AppToaster />
      <TailwindIndicator />
      <AppVersionIndicator />
    </>
  );
};

export { AppRoot };
