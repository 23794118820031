import { SessionDetailFormatter, SessionDetail as SessionInfo } from "@edutrackr/services/common";
import { Button, Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, Icons, Label, LabelDescription } from "@edutrackr/ui/components";
import { useTranslation } from 'react-i18next';

export interface SessionDetailDialogProps {
  item?: SessionInfo;
}

export function SessionDetailDialog(props: SessionDetailDialogProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.sessionDetailDialog',
  });
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" className="px-2" disabled={!props.item}>
          <Icons.Info className="!size-6 text-primary" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>{t('dialogs.title.label')}</DialogTitle>
        </DialogHeader>
        {
          props.item &&
          (
            <>
              <div>
                <Label>{t('dialogs.course.label')}</Label>
                <LabelDescription>{props.item.name}</LabelDescription>
              </div>

              <div>
                <Label>{t('dialogs.organizer.label')}</Label>
                <LabelDescription>
                  {props.item.organizer.firstName} {props.item.organizer.lastName}
                </LabelDescription>
              </div>

              <div>
                <Label>{t('dialogs.startDate.label')}</Label>
                <LabelDescription>
                  {SessionDetailFormatter.startTimeLabel(props.item)}
                </LabelDescription>
              </div>

              <div>
                <Label>{t('dialogs.topic.label')}</Label>
                <LabelDescription>
                  {props.item.topic}
                </LabelDescription>
              </div>

              {
                props.item!.description && (
                  <div>
                    <Label>{t('dialogs.description.label')}</Label>
                    <LabelDescription>
                      {props.item.description}
                    </LabelDescription>
                  </div>
                )
              }
            </>
          )
        }
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              {t('buttons.close')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default SessionDetailDialog;
