import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@edutrackr/shared/hooks';
import { AuthStatus } from '@edutrackr/shared/enums';

export interface PublicRouteProps {
  /**
   * The path to redirect to if the user is authenticated.
   */
  redirectTo: string;
  children: React.ReactNode;
}

/**
 * A component that renders its children only if the user is not authenticated.
 * Only works inside a <AuthProvider />.
 */
export function PublicRoute({
  redirectTo,
  children,
}: PublicRouteProps) {
  const { authStatus } = useAuth();

  if (authStatus === AuthStatus.Authenticated) {
    return (
      <Navigate to={redirectTo} replace />
    );
  }

  return children;
}

export default PublicRoute;
