export class ArrayUtils {
  static isEmpty<T>(array: Array<T>): boolean {
    return array.length === 0;
  }
  static isFirst<T>(array: Array<T>, index: number): boolean {
    return index === 0;
  }

  static isLast<T>(array: Array<T>, index: number): boolean {
    return index === array.length - 1;
  }

  static last<T>(array: Array<T>): T {
    return array[array.length - 1];
  }

  static unique<T>(array: Array<T>): Array<T> {
    return Array.from(new Set(array));
  }
}
