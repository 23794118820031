import { FilesetResolver, FaceLandmarker, FaceDetector, DrawingUtils, NormalizedLandmark } from "@mediapipe/tasks-vision";
import { InferenceDevice } from "@edutrackr/shared/enums";
import { AIConstants } from "@edutrackr/shared/constants";
// import { faceDetectorModel, faceLandmarkerModel } from "../../../../assets/ai-models";

export enum AIDrawingMode {
  Simple = "simple",
  Detailed = "detailed"
}

export class AIUtils {

  private static readonly DEFAULT_INFERENCE_DEVICE = AIConstants.DEFAULT_INFERENCE_DEVICE;
  private static readonly RUNNING_MODE = AIConstants.RUNNING_MODE;

  /**
   * Create a face detection model instance.
   * @param inferenceDevice The inference device to use (CPU or GPU).
   */
  static async createFaceDetector(inferenceDevice: InferenceDevice = AIUtils.DEFAULT_INFERENCE_DEVICE): Promise<FaceDetector> {
    const vision = await AIUtils.createVisionWasm();
    const faceDetector = await FaceDetector.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_detector/blaze_face_short_range/float16/1/blaze_face_short_range.tflite`,
          // modelAssetPath: faceDetectorModel, // Only for working with offline models
          delegate: inferenceDevice
        },
        runningMode: AIUtils.RUNNING_MODE,
      });

    return faceDetector;
  }

  /**
   * Create a face landmarker model instance.
   * @param inferenceDevice The inference device to use (CPU or GPU).
   */
  static async createFaceLandmarker(inferenceDevice: InferenceDevice = AIUtils.DEFAULT_INFERENCE_DEVICE): Promise<FaceLandmarker> {
    const vision = await AIUtils.createVisionWasm();
    const faceLandmarker = await FaceLandmarker.createFromOptions(
      vision,
      {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          // modelAssetPath: faceLandmarkerModel, // Only for working with offline models
          delegate: inferenceDevice
        },
        outputFaceBlendshapes: true,
        runningMode: AIUtils.RUNNING_MODE,
        numFaces: 1
      });

    return faceLandmarker;
  }

  /**
   * Remove duplicate loaded task scripts from the DOM.
   */
  static removeDuplicateTasks() {
    const srcPattern = /@mediapipe\/tasks-vision/;
    const scripts = Array.from(document.getElementsByTagName("script"));
    const filteredScripts = scripts.filter((script) => srcPattern.test(script.src));
    const uniqueScripts: string[] = [];
    for (const script of filteredScripts) {
      if (uniqueScripts.includes(script.src)) {
        script.remove();
      }
      else {
        uniqueScripts.push(script.src);
      }
    }
  }

  /**
   * Create a vision WASM instance. Used internally by the other AI creation methods.
   */
  private static async createVisionWasm() {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm" // Always update the NPM package version to match the latest task-vision version
    );
    return vision;
  }

}

export class AIDrawingUtils {

  /**
   * Create a DrawingUtils instance which helps to visualize the AI results on a canvas (e.g. landmarks).
   * @param canvas The canvas element to draw on.
   */
  static createDrawingUtils(canvas: HTMLCanvasElement): DrawingUtils | null {
    const canvasCtx = canvas.getContext("2d");
    if (!canvasCtx) {
      return null;
    }
    const drawingUtils = new DrawingUtils(canvasCtx);
    return drawingUtils;
  }

  /**
   * Draw landmarks on the canvas (using a DrawingUtils instance). Only supports single face landmarks.
   * @param drawingUtils The DrawingUtils instance to use.
   * @param faceLandmarks The face landmarks to draw.
   * @param mode The mode to use (simple or detailed).
   */
  static drawAllLandmarks(drawingUtils: DrawingUtils, faceLandmarks: NormalizedLandmark[], mode: AIDrawingMode = AIDrawingMode.Simple) {
    if (mode === AIDrawingMode.Simple) {
      AIDrawingUtils.drawSimpleLandmarks(drawingUtils, faceLandmarks);
    }
    else {
      AIDrawingUtils.drawDetailedLandmarks(drawingUtils, faceLandmarks);
    }
  }

  private static drawSimpleLandmarks(drawingUtils: DrawingUtils, faceLandmarks: NormalizedLandmark[]) {
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
      { color: "#C0C0C070" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
      { color: "#C0C0C070" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_TESSELATION,
      { color: "#C0C0C070", lineWidth: 1 }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
      { color: "#C0C0C070" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LIPS,
      { color: "#C0C0C070" }
    );
  }

  private static drawDetailedLandmarks(drawingUtils: DrawingUtils, faceLandmarks: NormalizedLandmark[]) {
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_TESSELATION,
      { color: "#C0C0C070", lineWidth: 1 }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
      { color: "#FF3030" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
      { color: "#FF3030" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
      { color: "#30FF30" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
      { color: "#30FF30" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
      { color: "#E0E0E0" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LIPS,
      { color: "#E0E0E0" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
      { color: "#FF3030" }
    );
    drawingUtils.drawConnectors(
      faceLandmarks,
      FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
      { color: "#30FF30" }
    );
  }

}
