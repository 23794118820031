import { Navigate, Outlet, RouteObject, createBrowserRouter } from "react-router-dom";
import { AnonymousLayout } from "@edutrackr/ui/layouts";
import authRoutes from "./features/auth/routes";
import redirectRoutes from "./features/redirect/routes";
import sessionsRoutes from "./features/sessions/routes";
import { PublicRoute } from "@edutrackr/ui/components";
import { defaultRedirectConfig } from "./shared/config";

const routes: RouteObject[] = [
  {
    element: (
      <PublicRoute redirectTo={defaultRedirectConfig.publicRoute}>
        <AnonymousLayout />
      </PublicRoute>
    ),
    children: authRoutes
  },
  {
    children: [
      ...sessionsRoutes,
    ]
  },
  {
    element: <Outlet />,
    children: redirectRoutes
  },
  {
    path: "/",
    element: <Navigate to="sessions" replace={true} />
  },
  {
    path: "*",
    element: <Navigate to="login" replace={true} />
  }
];

export const router = createBrowserRouter(routes);
