import { Outlet } from "react-router-dom";
import { BaseLayout } from "../base-layout";

export interface AnonymousLayoutProps {}

export function AnonymousLayout(props: AnonymousLayoutProps) {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
}

export default AnonymousLayout;
