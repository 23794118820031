import { createContext } from "react";
import { AuthLoginParams, AuthUser } from "@edutrackr/shared/types";
import { AuthStatus } from "@edutrackr/shared/enums";

interface AuthContextProps {
  user: AuthUser | null;
  authStatus: AuthStatus;
  accessToken: string | null;
  login: (params: AuthLoginParams) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  authStatus: AuthStatus.Pending,
  accessToken: null,
  login: (params: AuthLoginParams) => undefined,
  logout: () => undefined,
});


