import { ApiErrorData } from "@edutrackr/shared/types";
import { HttpError } from "./http-error";
import { ApiErrorParams } from "../types";

export class ApiError extends HttpError {

  errorData?: ApiErrorData;

  constructor(data: ApiErrorParams) {
    super(data);
    this.name = "ApiError";
    this.errorData = data.errorData;
  }

}
