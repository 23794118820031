import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { SentryClientConfig } from "../types";

/**
 * Use this class to initialize Sentry client.
 */
export class SentryClient {

  private readonly config: SentryClientConfig;

  constructor(config: SentryClientConfig) {
    this.config = config;
  }

  init(): void {
    const tracingOrigins = ["localhost", this.config.apiUrl];

    Sentry.init({
      dsn: this.config.dsn,
      release: this.config.release,
      environment: this.config.environment,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],

      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: tracingOrigins,

      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions

      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

}
