import * as signalR from "@microsoft/signalr";
import { RealtimeClientConfig as RealtimeClientConfig } from "../types/realtime";

export class RealtimeClient {

  private readonly config: RealtimeClientConfig;
  private readonly connection: signalR.HubConnection;

  constructor(config: RealtimeClientConfig) {
    this.config = config;
    this.connection = this.createConnection(config);
  }

  public async start(): Promise<void> {
    await this.connection.start();
  }
  public async stop(): Promise<void> {
    await this.connection.stop();
  }

  public async call<T>(methodName: string, ...args: unknown[]): Promise<T> {
    return await this.connection.invoke(methodName, ...args);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public on(methodName: string, callback: (...args: any[]) => void): void {
    this.connection.on(methodName, callback);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public off(methodName: string, callback?: (...args: any[]) => void): void {
    if (!callback) {
      this.connection.off(methodName);
      return;
    }
    this.connection.off(methodName, callback);
  }

  public onConnectionClose(callback: (error?: Error) => void): void {
    this.connection.onclose((error) => {
      console.error("Connection closed", error);
      callback(error);
    });
  }

  public onConnectionReconnecting(callback: (error?: Error) => void): void {
    this.connection.onreconnecting(callback);
  }

  public onConnectionReconnected(callback: (connectionId?: string) => void): void {
    this.connection.onreconnected(callback);
  }

  private createConnection(config: RealtimeClientConfig): signalR.HubConnection {
    const connectionOptions: signalR.IHttpConnectionOptions = {
      withCredentials: false
    };
    const authorizationToken = config.authorizationToken;
    if (authorizationToken) {
      connectionOptions.accessTokenFactory = () => {
        if (typeof authorizationToken === "function") {
          const token = authorizationToken();
          return new Promise<string>((resolve) => {
            resolve(token);
          });
        }
        return authorizationToken;
      };
    }
    return new signalR.HubConnectionBuilder()
      .withUrl(this.config.baseUrl, connectionOptions)
      .build();
  }

}
