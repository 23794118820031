import { AuthLoginParams, AuthRefreshParams, AuthUser, CurrentUserInfo } from "@edutrackr/shared/types";
import { StringUtils } from "@edutrackr/shared/utils";
import { LoginResponse } from "../types";

export class AuthMapper {

  static loginResponseToLoginParams(response: LoginResponse) {
    return {
      accessToken: response.tokens.accessToken,
      refreshToken: response.tokens.refreshToken,
      expiresIn: response.tokens.accessTokenExpireTime,
      user: {
        id: response.user.id,
        firstName: response.user.firstName,
        lastName: response.user.lastName,
        email: response.user.email,
        role: response.activeRole
      },
    } satisfies AuthLoginParams;
  }

  static loginResponseToRefreshParams(response: LoginResponse) {
    return {
      accessToken: response.tokens.accessToken,
      refreshToken: response.tokens.refreshToken,
      expiresIn: response.tokens.accessTokenExpireTime,
      user: {
        id: response.user.id,
        firstName: response.user.firstName,
        lastName: response.user.lastName,
        email: response.user.email,
        role: response.activeRole
      },
    } satisfies AuthRefreshParams;
  }

  static authUserToUserInfo(user: AuthUser) {
    const fullName = `${StringUtils.getFirstWord(user.firstName)} ${StringUtils.getFirstWord(user.lastName)}`;
    return {
      name: fullName,
      initials: StringUtils.getInitials(fullName),
      role: user.role,
      email: user.email,
    } satisfies CurrentUserInfo;
  }

}
