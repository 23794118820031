import * as z from "zod";
import i18n from "../../../shared/i18n";
import { AppConstants } from "@edutrackr/shared/constants";

export type LoginFormValues = {
  email: string;
  password: string;
  acceptTerms: boolean;
}

type LoginFormSchema = z.ZodSchema<LoginFormValues>;

export const loginFormSchema = () => z.object({
  email: z.string().email(i18n.t("auth:schemas.loginForm.fields.email")),
  password: z.string().min(AppConstants.securiry.MIN_PASSWORD_LENGTH, {
    message: i18n.t("auth:schemas.loginForm.fields.password", {
      minLength: AppConstants.securiry.MIN_PASSWORD_LENGTH
    })
  }),
  acceptTerms: z.boolean().refine((value) => value, {
    message: i18n.t("auth:schemas.loginForm.fields.acceptTerms")
  })
}) satisfies LoginFormSchema;

