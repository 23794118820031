import { AuthMapper, AuthService, RealtimeSessionService, RoleService, SessionService, AssistantService as CommonAssistantService } from "@edutrackr/services/common";
import { AnalyticsService } from "@edutrackr/services/student";
import { EnvConfig } from "@edutrackr/shared/config";
import { HttpConstants } from "@edutrackr/shared/constants";
import { HttpClient, I18nUtils, LocalStorageUtils } from "@edutrackr/shared/utils";
import { ClientApplication, StorageKeys } from "@edutrackr/shared/enums";
import { AuthRefreshParams } from "@edutrackr/shared/types";

export const httpClient = new HttpClient({
  baseUrl: EnvConfig.API_URL,
  headers: {
    [HttpConstants.headers.CLIENT_APPLICATION]: ClientApplication.Student,
  },
  authorizationToken: () => LocalStorageUtils.getItem<string>(StorageKeys.AccessToken),
  language: () => I18nUtils.getCurrentLanguage()
});

export const realtimeClientConfig = {
  baseUrl: EnvConfig.REALTIME_URL,
  authorizationToken: () => LocalStorageUtils.getItem<string>(StorageKeys.AccessToken) ?? "",
};

export const commonAssistantService = new CommonAssistantService(httpClient);
export const authService = new AuthService(httpClient);
export const roleService = new RoleService(httpClient);
export const analyticsService = new AnalyticsService(httpClient);
export const sessionService = new SessionService(httpClient);
export const realtimeSessionService = new RealtimeSessionService(realtimeClientConfig);

export const refreshSessionHandler = async (refreshToken: string): Promise<AuthRefreshParams> => {
  const response = await authService.refreshToken({ refreshToken });
  return AuthMapper.loginResponseToRefreshParams(response.data!);
};
