import { I18nUtils, TimeUtils } from '@edutrackr/shared/utils';
import { I18nConstants } from '@edutrackr/shared/constants';
import Resources from '../../@types/resources';

type Namespaces = keyof Resources;

export const configureLocale = () => {
  const language = I18nUtils.getCurrentLanguage();
  TimeUtils.configureLocale(language);
}

const i18n = I18nUtils.configureI18n({
  debug: false,
  initialNamespacesToLoad: [
    I18nConstants.DEFAULT_NAMESPACE,
    'auth',
    "sessions"
  ] satisfies Namespaces[],
  onInitialized: () => {
    configureLocale();
  }
});
I18nUtils.loadNamespaces([
  'common',
  'auth',
  'sessions'
  ] satisfies Namespaces[]);

export default i18n;
