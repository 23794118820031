import { HttpClient } from "@edutrackr/shared/utils";
import axios, { AxiosError } from "axios";
import { useEffect } from "react";

interface UseErrorInterceptorProps {
  httpClient: HttpClient;
  onError: (error: AxiosError) => void;
}

export const useErrorInterceptor = ({ httpClient, onError }: UseErrorInterceptorProps) => {
  useEffect(() => {
    const interceptor = httpClient.addResponseInterceptor({
      onRejected: (error) => {
        if (!axios.isAxiosError(error)) {
          return Promise.reject(error);
        }
        onError(error);
        return Promise.reject(error);
      }
    });
    return () => {
      interceptor.clear();
    }
  }, [httpClient, onError]);
}
