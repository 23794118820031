import { RealtimeClientConfig } from "../types";
import { RealtimeClient } from "./realtime-client";

export class BaseRealtimeService {
  protected readonly realtimeClient: RealtimeClient;

  constructor(
    protected readonly hubName: string,
    config: RealtimeClientConfig
  ) {
    this.realtimeClient = new RealtimeClient({
      ...config,
      baseUrl: `${config.baseUrl}/${hubName}`
    });
  }
}
