import { useEffect } from 'react';
import { I18nUtils } from '@edutrackr/shared/utils';

/**
 * Hook to listen to language changes and reconfigure the locale.
 */
export function useLanguageChange(configureLocale: () => void) {

  useEffect(() => {
    const cleanup = I18nUtils.onLanguageChanged(() => {
      configureLocale();
    });
    return () => {
      cleanup();
    };
  }, [configureLocale]);

}
