import { Toaster as SonnerToaster } from "../sonner";

type AppToasterProps = React.ComponentProps<typeof SonnerToaster>

/**
 * Custom Toaster component
 */
const AppToaster = ({ ...props }: AppToasterProps) => {

  return (
    <SonnerToaster
      closeButton={true}
      richColors={true}
      duration={4000}
      {...props}
    />
  )
};

export { AppToaster };
