import { validateEnumValue, throwError } from "./utils";
import { AppEnvironment } from "@edutrackr/shared/enums";

export const EnvConfig = {
  ENVIRONMENT: process.env.NODE_ENV && validateEnumValue(
    AppEnvironment,
    process.env.NODE_ENV,
    'NODE_ENV is not valid'
  ) ? process.env.NODE_ENV as AppEnvironment : throwError('NODE_ENV is not defined') as AppEnvironment,
  API_URL: import.meta.env.VITE_API_URL ?? throwError('VITE_API_URL is not defined') as string,
  REALTIME_URL: import.meta.env.VITE_REALTIME_URL ?? throwError('VITE_REALTIME_URL is not defined') as string,
  sentry: {
    STUDENT_DSN: import.meta.env.VITE_STUDENT_SENTRY_DSN ?? throwError('VITE_STUDENT_SENTRY_DSN is not defined') as string,
  },
};
