/**
 * Common state slices.
 */

import { StateCreator } from "zustand";
import { TimerStatus } from "@edutrackr/shared/enums";

/**
 * Manual timer slice. Helps to keep track of the elapsed time.
 *
 * It also helps to keep track of the dead time (time when the timer was paused).
 * However, dead time is not exposed and does not actively trigger a re-render
 * (it is only updated when the timer is resumed).
 */
export interface TimerSlice {

  /**
   * The timer status.
   */
  status: TimerStatus;

  /**
   * The overall elapsed time. Calculated by subtracting the start time from the end time, as well as the dead time.
   */
  elapsedTime: number;

  /**
   * The start time. By default, set to `0`.
   * For internal use only.
   */
  _startTime: number;

  /**
   * The time when the timer was last paused. By default, set to `0`.
   * For internal use only.
   */
  _lastPausedTime: number;

  /**
   * The overall elapsed dead time (time when the timer was paused).
   */
  _elapsedDeadTime: number;

  /**
   * Starts the timer.
   * @param time The time to set.
   * @throws If the timer has already been started.
   */
  start: (time: number) => void;

  /**
   * Updates the current time (eg. the current time of the video player). Only works if the timer is running.
   * @param time The time to set.
   * @throws If the timer has not been started.
   */
  updateTime: (time: number) => void;

  /**
   * Pauses the timer.
   * @param time The time to pause at.
   * @throws If the timer has already been paused.
   */
  pause: (time: number) => void;

  /**
   * Resumes the timer.
   * @param time The time to resume at.
   * @returns The elapsed time from the last paused time.
   * @throws If the timer has not been paused.
   */
  resume: (time: number) => number;

  /**
   * Method to check if the elapsed has exceeded the given time limit.
   * @param time The amount of time to check against.
   * @returns `true` if the time state has exceeded the given time, `false` otherwise.
   */
  hasExceededTime: (time: number) => boolean;

  /**
   * Resets the start and elapsed time.
   */
  reset: () => void;
}

/**
 * Creates a time slice.
 */
export const createTimerSlice: StateCreator<TimerSlice> = (set, get) => ({
  status: TimerStatus.Idle,
  elapsedTime: 0,

  _startTime: 0,
  _lastPausedTime: 0,
  _elapsedDeadTime: 0,

  start: (time) => {
    if (get().status !== TimerStatus.Idle) {
      throw new Error("Timer has already been started.");
    }
    set(() => ({
      status: TimerStatus.Running,
      _startTime: time
    }));
  },

  updateTime: (time) => {
    if (get().status !== TimerStatus.Running) {
      throw new Error("Timer should be running.");
    }
    set(() => ({
      elapsedTime: time - get()._startTime - get()._elapsedDeadTime
    }));
  },

  pause: (time) => {
    if (get().status !== TimerStatus.Running) {
      throw new Error("Timer should be running.");
    }
    set(() => ({
      status: TimerStatus.Paused,
      _lastPausedTime: time,
    }));
  },

  resume: (time) => {
    if (get().status !== TimerStatus.Paused) {
      throw new Error("Timer has not been paused.");
    }
    const elapsedTime = time - get()._lastPausedTime;
    set(() => ({
      status: TimerStatus.Running,
      _elapsedDeadTime: get()._elapsedDeadTime + elapsedTime,
    }));
    return elapsedTime;
  },

  hasExceededTime: (time) => get().elapsedTime >= time,

  reset: () => set(() => ({
    status: TimerStatus.Idle,
    elapsedTime: 0,
    _startTime: 0,
    _lastPausedTime: 0,
    _elapsedDeadTime: 0,
  })),
});
