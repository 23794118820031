import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { LoginRequest, AuthMapper } from "@edutrackr/services/common";
import { CommonMutationKeys } from "@edutrackr/shared/enums";
import { useAuth } from "@edutrackr/shared/hooks";
import { ErrorUtils } from "@edutrackr/shared/utils";
import { authService } from "../../../shared/services";
import { LoginFormValues } from "../schemas/login-form.schema";
import i18n from "../../../shared/i18n";

export const useLoginMutation = () => {
  const { login } = useAuth();

  const loginMutation = useMutation({
    mutationKey: [CommonMutationKeys.Login as const],
    mutationFn: async (values: LoginFormValues) => {
      const request: LoginRequest = {
        email: values.email,
        password: values.password,
      };
      return await authService.login(request);
    },
    onSuccess: data => {
      const response = data.data!;
      login(AuthMapper.loginResponseToLoginParams(response));
      toast.success(i18n.t('auth:hooks.useLoginMutation.success.title'), {
        description: i18n.t('auth:hooks.useLoginMutation.success.message', {
          fullName: `${response.user.firstName} ${response.user.lastName}`
        }),
      });
    },
    onError: e => {
      const errorDetail = ErrorUtils.getErrorDetail(e);
      toast.error(i18n.t('auth:hooks.useLoginMutation.error.title'), {
        description: errorDetail.message
      });
    },
  });

  return loginMutation;
}
