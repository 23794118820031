import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { useTranslation } from 'react-i18next';
import { useQueryClient } from "@tanstack/react-query";
import { CommonQueryKeys } from "@edutrackr/shared/enums";
import { MainContentContainer } from "@edutrackr/ui/layouts";
import { Button, Card, Icons, ResizableHandle, ResizablePanel, ResizablePanelGroup, ScrollArea } from "@edutrackr/ui/components";
import { AICamera } from "../../components/ai-camera";
import { SessionDetailDialog } from "../../components/session-detail-dialog";
import { useSessionQuery } from "../../hooks";
import { realtimeSessionService } from "../../../../shared/services";
import { Assistant } from "../../components/assistant";
import AiSdkCamera from "../../components/ai-sdk/ai-sdk-camera";


export interface CurrentSessionPageProps { }

export function CurrentSessionPage(props: CurrentSessionPageProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'pages.currentSessionPage',
  });
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const isInitialRender = useRef(true);
  const [isCameraRunning, setIsCameraRunning] = useState(false);
  const toastRef = useRef<ReturnType<typeof toast> | null>(null);

  const sessionQuery = useSessionQuery(sessionId!);
  const queryClient = useQueryClient();

  const connect = async (sessionId: string) => {
    try {
      await realtimeSessionService.connect();
      await realtimeSessionService.addUserToSession(sessionId);
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  const disconnect = () => {
    realtimeSessionService.disconnect()
      .then(() => {
        setIsCameraRunning(false);
        navigate("/sessions");
        queryClient.removeQueries({ queryKey: [CommonQueryKeys.GetSessionById] });
      })
      .catch((e) => {
        console.log(e)
        console.log("Error disconnecting from session");
      });
  }

  useEffect(() => {
    if (sessionQuery.isSuccess) {
      connect(sessionId!)
        .then(() => {
          console.log("Connected to realtime");
          setIsCameraRunning(true);
        })
        .catch((e) => {
          console.log(e)
          console.log("Error connecting to realtime");
        });
    }
  }, [sessionQuery.isSuccess]);

  useEffect(() => {
    if (sessionQuery.isError) {
      const currentToast = toast(t('toast.title'), {
        description: t('toast.canNotStablishConnection'),
        action: {
          label: t('toast.buttons.refresh'),
          onClick: () => {
            navigate(0);
            toast.dismiss(currentToast);
          },
        },
      });
      disconnect();
    }
  }, [sessionQuery.isError]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      realtimeSessionService.onConnectionClosed(error => {
        if (!error) {
          return;
        }
        console.error("Connection closed:", error);
        const currentToast = toast(t('toast.title'), {
          description: t('toast.lostConnection'),
          action: {
            label: t('toast.buttons.refresh'),
            onClick: () => {
              navigate(0);
              toast.dismiss(currentToast);
            },
          },
        });
        toastRef.current = currentToast;
      });
      realtimeSessionService.onConnectionReconnected(() => {
        console.log("Reconnected to realtime");
        if (toastRef.current) {
          toast.dismiss(toastRef.current);
          toastRef.current = null;
        }
      });
    }
    const clean = realtimeSessionService.onCloseSession(message => {
      console.log("Session closed:", message);
      toast(t('toast.sessionClosed'), {
        description: message,
      });
      disconnect();
    });
    return () => {
      clean();
    }
  }, []);


  return (
    <ResizablePanelGroup direction="horizontal" className="flex-grow">
      <ResizablePanel defaultSize={75} className="flex flex-col">
        <ScrollArea className="max-h-[calc(100vh-(var(--header-height)))]">
          <MainContentContainer minimalMargin>
            <div className="mx-auto max-w-4xl">
              <Card className="flex justify-between gap-3 px-4 py-2 mb-4">
                <div className="flex items-center gap-2">
                  <SessionDetailDialog item={sessionQuery.data} />
                  <div className="flex flex-col">
                    <span className="text-md text-gray-500">{sessionQuery.data?.name}</span>
                  </div>
                </div>
                <Button
                  variant="destructive"
                  onClick={disconnect}
                  disabled={sessionQuery.isLoading}
                >
                  {t('toast.buttons.leaveSession')}
                </Button>
              </Card>
              {
                isCameraRunning
                  ? (
                    <>
                      <AiSdkCamera />
                    </>
                  )
                  : (
                    <Card className="w-full aspect-video flex items-center justify-center">
                      <Icons.Spinner className="w-12 h-12 animate-spin text-primary" />
                    </Card>
                  )
              }
            </div>
          </MainContentContainer>
        </ScrollArea>
      </ResizablePanel>
      <ResizableHandle />
      <ResizablePanel defaultSize={25} minSize={25} maxSize={50} className="hidden sm:flex flex-col bg-white">
        <Assistant session={sessionQuery.data} />
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}

export default CurrentSessionPage;
