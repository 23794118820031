import { MessageContentReference } from "@edutrackr/shared/types";
import { Button, HoverCard, HoverCardContent, HoverCardTrigger, Icons, ScrollArea, Title } from "@edutrackr/ui/components";


interface ContentReferenceCardProps {
  contentReference: MessageContentReference;
}

export function ContentReferenceCard(props: ContentReferenceCardProps) {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Button type="button" variant="outline" className="justify-start items-start gap-1 w-100 h-auto px-2 py-1 overflow-hidden text-primary hover:text-primary hover:bg-slate-50 text-xs">
          <Icons.File className="!size-3 mt-0.5 shrink-0" />
          <span className="text-left max-w-full text-ellipsis overflow-hidden">{props.contentReference.source}</span>
        </Button>
      </HoverCardTrigger>
      <HoverCardContent align="start" className="flex flex-grow p-0">
        <ScrollArea className="flex-grow max-h-48 px-4">
          <div className="flex flex-col gap-2 h-max py-4">
            <Title type="h5" className="line-clamp-3">
              {props.contentReference.source}
            </Title>
            <p className="whitespace-pre-wrap text-xs">
              {props.contentReference.text.trim()}
            </p>
          </div>
        </ScrollArea>
      </HoverCardContent>
    </HoverCard>
  );
}
