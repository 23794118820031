import { formatDistanceToNow, getDefaultOptions, intlFormat, setDefaultOptions } from 'date-fns';
import { Locale, enUS, es } from 'date-fns/locale'
import { ErrorUtils } from './error-utils';
import { SupportedLanguage } from '@edutrackr/shared/constants';


const LOCALES_BY_LANGUAGE: Record<SupportedLanguage, Locale> = {
  "en-US": enUS,
  "es-ES": es
};

export class TimeUtils {

  /**
   * Returns the current date without time (time is set to 00:00:00.000).
   */
  static today(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  /**
   * Configures the default locale for date-fns globally.
   * @param locale The locale to configure.
   */
  static configureLocale(locale: string): void {
    const localeObject = (LOCALES_BY_LANGUAGE as Record<string, Locale>)[locale]
      ?? ErrorUtils.throwError(`Locale ${locale} is not supported`);
    setDefaultOptions({ locale: localeObject });
  }

  /**
   * Returns the default locale for date-fns.
   */
  static getCurrentLocale(): Locale {
    return getDefaultOptions().locale as Locale;
  }

  /**
   * Merges two dates into a new date, with the date part of the first date and the time part of the second date.
   * @param datePart The date containing the date part. If null or undefined, the current date is used.
   * @param timePart The date containing the time part. If null or undefined, the time from the first date is used.
   * @returns A new date with the merged date and time parts.
   */
  static mergeDateAndTime(datePart?: Date, timePart?: Date): Date {
    const finalDatePart = datePart ?? new Date();
    const finalTimePart = timePart ?? finalDatePart;
    return new Date(
      finalDatePart.getFullYear(),
      finalDatePart.getMonth(),
      finalDatePart.getDate(),
      finalTimePart.getHours(),
      finalTimePart.getMinutes(),
      finalTimePart.getSeconds(),
      finalTimePart.getMilliseconds()
    );
  }

  /**
   * Formats date as distance to now with suffix (e.g. "hace 2 horas").
   * @param date
   */
  static formatDistanceToNow(date: Date, addSuffix = true): string {
    return formatDistanceToNow(date, { addSuffix: addSuffix });
  }

  /**
   * Format date and time.
   * @param date
   */
  static formatDateTime(date: Date): string {
    return intlFormat(date, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23'
    });
  }

}
