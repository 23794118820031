import i18n from 'i18next';
import { HttpError } from './errors';
import { ErrorDetail } from './types';
import { I18nCommonKeys } from '@edutrackr/shared/constants';

export class ErrorUtils {

  static throwError(message: string): never {
    throw new Error(message);
  }

  static getErrorDetail(error: Error): ErrorDetail {
    if (error instanceof HttpError) {
      return {
        message: error.message,
        error
      };
    }
    return {
      message: i18n.t(I18nCommonKeys.messages.unknownError),
      error
    }
  }

}
