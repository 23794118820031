export class JsonUtils {

  static tryDeserialize<T>(json: string): T | null {
    try {
      return JSON.parse(json);
    }
    catch (e) {
      return null;
    }
  }

  static trySerialize<T>(value: T): string | null {
    try {
      return JSON.stringify(value);
    }
    catch (e) {
      return null;
    }
  }

}
