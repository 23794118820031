import { BaseService, HttpClient } from "@edutrackr/shared/utils";
import { BaseApiResponse } from "@edutrackr/shared/types";
import { UploadSessionAttendanceMetricsRequest, UploadVideoForAnalysisRequest } from "./types/analytics";

export class AnalyticsService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  uploadVideoForAnalysis(request: UploadVideoForAnalysisRequest): Promise<BaseApiResponse> {
    return this.httpClient.formData<BaseApiResponse>('/analytics/uploadVideoForAnalysis', request);
  }

  uploadSessionAttendanceMetrics(request: UploadSessionAttendanceMetricsRequest): Promise<BaseApiResponse> {
    return this.httpClient.post<BaseApiResponse>('/analytics/uploadSessionAttendanceMetrics', request);
  }

}
