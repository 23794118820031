import { SessionDetail } from "@edutrackr/services/common";
import { AssistantChat } from "./assistant-chat";
import { ChatLoadingOverlay, ChatUnavailableOverlay } from "@edutrackr/ui/components";

interface AssistantProps {
  session?: SessionDetail;
}

export function Assistant({ session }: AssistantProps) {
  if (!session) {
    return <ChatLoadingOverlay />;
  }

  if (!session.planning) {
    return <ChatUnavailableOverlay />;
  }

  return <AssistantChat session={session} />;
}
