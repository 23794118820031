import { useCallback, useState } from "react";
import { HttpStatusCode } from "axios";
import { useLocalStorage } from "@uidotdev/usehooks";
import { AuthContext } from "@edutrackr/shared/contexts";
import { AuthLoginParams, AuthRefreshParams, AuthUser } from "@edutrackr/shared/types";
import { AuthStatus, StorageKeys } from "@edutrackr/shared/enums";
import { HttpClient } from "@edutrackr/shared/utils";
import { useRefreshSession } from "./use-refresh-session";
import { useSessionTimeout } from "./use-session-timeout";
import { useErrorInterceptor } from "./use-error-interceptor";
import { useLogout } from "./use-logout";


interface AppAuthProviderProps {
  /** HTTP client instance. */
  httpClient: HttpClient;

  /** Refresh session handler. */
  onRefresh: (refreshToken: string) => Promise<AuthRefreshParams>;

  /** Children. */
  children: React.ReactNode;
}

export const AppAuthProvider = ({
  httpClient,
  onRefresh,
  children
}: AppAuthProviderProps) => {
  const [accessToken, setAccessToken] = useLocalStorage<string | null>(StorageKeys.AccessToken, null);
  const [refreshToken, setRefreshToken] = useLocalStorage<string | null>(StorageKeys.RefreshToken, null);
  const [user, setUser] = useState<AuthUser | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Pending);

  const refreshSession = useRefreshSession({
    initialRefreshToken: refreshToken,
    onRefresh,
    onSuccess: (data) => {
      onLogin(data);
    },
    onError: () => {
      onLogout();
    },
  });

  const sessionTimeout = useSessionTimeout();

  const onLogin = useCallback((data: AuthLoginParams) => {
    setAccessToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    setUser(data.user);
    setAuthStatus(AuthStatus.Authenticated);
    sessionTimeout.schedule(() => {
      refreshSession(data.refreshToken);
    }, data.expiresIn);
  }, [setAccessToken, setRefreshToken, sessionTimeout, refreshSession]);

  const onLogout = useCallback(() => {
    if (authStatus === AuthStatus.NotAuthenticated) {
      return;
    }
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    setAuthStatus(AuthStatus.NotAuthenticated);
    sessionTimeout.clear();
  }, [authStatus, setAccessToken, setRefreshToken, sessionTimeout]);

  const logout = useLogout(onLogout);

  useErrorInterceptor({
    httpClient,
    onError: (error) => {
      const status = error.response?.status;
      if (status === HttpStatusCode.Unauthorized) {
        refreshSession(refreshToken);
      }
    },
  });

  return (
    <AuthContext.Provider value={{
      user,
      authStatus,
      accessToken,
      login: onLogin,
      logout,
    }}>
      {children}
    </AuthContext.Provider>
  );
};

