import { useTranslation } from "react-i18next";
import { Button, Icons } from "@edutrackr/ui/components";
import { I18nCommonKeys } from "@edutrackr/shared/constants";

interface ChatErrorOverlayProps {
  onTryAgain: () => void;
}

export function ChatErrorOverlay(props: ChatErrorOverlayProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-grow justify-center items-center gap-3 px-4 py-3">
      <Icons.BotOff className="w-12 h-12 text-muted-foreground pb-2" />
      <p className="text-center text-muted-foreground text-sm text-balance">
        {t(I18nCommonKeys.components.chat.overlays.error.message)}
      </p>
      <Button variant="secondary" onClick={props.onTryAgain}>
        <Icons.Refresh className="!size-4" />
        {t(I18nCommonKeys.components.chat.overlays.error.buttons.tryAgain)}
      </Button>
    </div>
  );
}
