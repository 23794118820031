import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { I18nConstants } from '@edutrackr/shared/constants';

export interface I18nConfig {

  /**
   * The default language to load. By default, it will load the English language.
   */
  defaultLanguage?: string;

  /**
   * The default namespace to load. By default, it will load the common namespace.
   */
  defaultNamespace?: string;

  /**
   * Namespaces to load on initialization. By default, it will load the default namespace.
   */
  initialNamespacesToLoad?: string[];

  /**
   * Whether to enable debug mode. By default, it is disabled.
   */
  debug?: boolean;

  /**
   * Event handler when i18n is initialized.
   */
  onInitialized?: () => void;

}

const defaultConfig: I18nConfig = {
  defaultLanguage: I18nConstants.DEFAULT_LANGUAGE,
  defaultNamespace: I18nConstants.DEFAULT_NAMESPACE,
  initialNamespacesToLoad: [I18nConstants.DEFAULT_NAMESPACE],
  debug: false,
  onInitialized: () => undefined,
};

export class I18nUtils {

  /**
   * Configures the i18n instance.
   * @param config The i18n configuration.
   */
  static configureI18n(config: I18nConfig): typeof i18n {
    if (i18n.isInitialized) {
      return i18n;
    }

    const {
      defaultLanguage,
      defaultNamespace,
      initialNamespacesToLoad,
      debug,
      onInitialized,
    } = { ...defaultConfig, ...config };

    i18n
      .use(Backend) // Lazy loads translations from /public/locales
      .use(LanguageDetector) // Detects the user's language
      .use(initReactI18next) // Passes i18n down to react-i18next
      .init({
        fallbackLng: defaultLanguage,
        supportedLngs: I18nConstants.SUPPORTED_LANGUAGES,
        defaultNS: defaultNamespace,
        ns: initialNamespacesToLoad,
        debug,
        interpolation: {
          escapeValue: false, // Not needed for react as it escapes by default
        },
        react: {
          useSuspense: false,
        },
        backend: {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
      }, onInitialized);
    return i18n;
  }

  /**
   * Loads the given namespaces.
   * @param namespaces The namespaces to load.
   */
  static loadNamespaces(namespaces: string[]): void {
    i18n.loadNamespaces(namespaces);
  }

  /**
   * Changes the language.
   * @param language The language to change to.
   */
  static changeLanguage(language: string) {
    return i18n.changeLanguage(language);
  }

  /**
   * Gets the current language.
   */
  static getCurrentLanguage(): string {
    return i18n.resolvedLanguage ?? I18nConstants.DEFAULT_LANGUAGE;
  }

  /**
   * Listens to language change event.
   */
  static onLanguageChanged(callback: () => void): () => void {
    i18n.on('languageChanged', callback);
    return () => i18n.off('languageChanged', callback);
  }

}
