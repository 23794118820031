import { CommonQueryKeys } from "@edutrackr/shared/enums";
import { useQuery } from "@tanstack/react-query";
import { sessionService } from "../../../shared/services";


export const useUserSessionsQuery = () => {
  const userSessionsQuery = useQuery({
    queryKey: [CommonQueryKeys.GetUserSessions as const],
    queryFn: async () => {
      const { data } = await sessionService.getUserSessions({
        isEnabled: true,
        isActive: true,
        isSessionEnded: false,
        // isSessionStarted: true,
        pageIndex: 0,
        pageSize: 20,
      });
      return data;
    },
    refetchInterval: 10000,
  });

  return userSessionsQuery;
}
