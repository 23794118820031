import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export interface AppQueryProviderProps {
  client: QueryClient;
  children: React.ReactNode;
}

export const AppQueryProvider = ({ client, children }: AppQueryProviderProps) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} position='right' buttonPosition='bottom-left' />
    </QueryClientProvider>
  );
};
