import { useTranslation } from 'react-i18next';
import { FilePondFile, FileStatus } from 'filepond';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { I18nCommonKeys } from '@edutrackr/shared/constants';
import { FileUtils } from '@edutrackr/shared/utils';
import { useAcceptedFileTypes } from '@edutrackr/shared/hooks';
import 'filepond/dist/filepond.min.css';

// Register FilePond plugins
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

/**
 * Filters out duplicate files from the list of files (based on filename). Keeps the first occurrence of each file.
 * @param files List of files to filter
 * @returns List of unique files
 */
const filterUniqueFiles = (files: FilePondFile[]) => {
  const fileNames = new Set<string>();
  return files.filter((file) => {
    if (fileNames.has(file.filename)) {
      return false;
    }
    fileNames.add(file.filename);
    return true;
  });
}

/**
 * Custom validation function to validate the file type based on the MIME type or file extension.
 */
const validateTypeDetectType = (source: File, type: string, acceptedFileTypes: string[]) => new Promise<string>((resolve, reject) => {
  if (type && acceptedFileTypes.some(fileType => fileType === type)) {
    // Accepted MIME type
    resolve(type);
    return;
  }
  const uploadedFileExtensionWithoutDot = source.name.split('.').pop()?.toLowerCase();
  if (!uploadedFileExtensionWithoutDot) {
    // Unrecognized file extension
    reject();
    return;
  }
  const uploadedFileExtension = `.${uploadedFileExtensionWithoutDot}`;
  const isExtensionAllowed = acceptedFileTypes.some(fileType => fileType === uploadedFileExtension);
  if (isExtensionAllowed) {
    // Accepted file extension
    resolve(type || FileUtils.getCustomMimeType(uploadedFileExtensionWithoutDot));
    return;
  }
  else {
    // File type not allowed
    reject();
    return;
  }

});

const i18nConfig = I18nCommonKeys.components.fileUploader;

export interface FileUploaderProps {
  files: FilePondFile[];
  setFiles: (files: FilePondFile[]) => void;
  setFilesReady: (filesReady: boolean) => void;
  maxFiles?: number;
  maxFileSize?: string;

  /**
   * List of accepted file types. The file types should be in the format of file extensions (e.g. 'pdf', 'docx'). The file types are case-insensitive and any extension with a preceding dot is also accepted (e.g. '.pdf', '.docx').
   */
  acceptedFileTypes: string[];
}

export function FileUploader({
  files,
  setFiles,
  setFilesReady,
  maxFiles = 10,
  maxFileSize = '5MB',
  acceptedFileTypes: originalAcceptedFileTypes
}: FileUploaderProps) {
  const { t } = useTranslation();

  const singleFileUpload = maxFiles === 1;
  const maxFileSizeString = FileUtils.normalizeFileSize(maxFileSize);
  const {
    acceptedFileTypes,
    acceptedFileTypesString
  } = useAcceptedFileTypes(originalAcceptedFileTypes);

  const handleUpdateFiles = (newFiles: FilePondFile[]) => {
    const uniqueFiles = filterUniqueFiles(newFiles);
    setFiles(uniqueFiles);
    setFilesReady(
      newFiles.length > 0 &&
      newFiles.every((file) => file.status === FileStatus.IDLE)
    );
  }

  return (
    <>
      <FilePond
        name="files"
        files={files as any} // eslint-disable-line @typescript-eslint/no-explicit-any
        maxFiles={maxFiles}
        maxFileSize={maxFileSize}
        allowMultiple={!singleFileUpload}
        acceptedFileTypes={acceptedFileTypes}
        fileValidateTypeDetectType={(source, type) => validateTypeDetectType(source, type, acceptedFileTypes)}
        labelIdle={t(i18nConfig.labelIdle)}
        labelButtonRemoveItem={t(I18nCommonKeys.labels.remove)}
        labelMaxFileSizeExceeded={t(i18nConfig.labelMaxFileSizeExceededTitle)}
        labelMaxFileSize={t(i18nConfig.labelMaxFileSizeExceededMessage.key, {
          [i18nConfig.labelMaxFileSizeExceededMessage.params.maxFileSize]: maxFileSizeString
        })}
        labelFileTypeNotAllowed={t(i18nConfig.labelFileTypeNotAllowedTitle)}
        fileValidateTypeLabelExpectedTypes={t(i18nConfig.labelFileTypeNotAllowedMessage.key, {
          [i18nConfig.labelFileTypeNotAllowedMessage.params.acceptedTypes]: acceptedFileTypesString
        })}
        credits={false}
        checkValidity
        onupdatefiles={handleUpdateFiles}
      />
      <div className="text-xs text-muted-foreground -mt-1">
        <div className="flex gap-1">
          {
            singleFileUpload ? (
              t(i18nConfig.bottomSingleFileMessage.key, {
                [i18nConfig.bottomSingleFileMessage.params.maxFileSize]: maxFileSizeString
              })
            ) : (
              t(i18nConfig.bottomMultipleFilesMessage.key, {
                [i18nConfig.bottomMultipleFilesMessage.params.maxFiles]: maxFiles,
                [i18nConfig.bottomMultipleFilesMessage.params.maxFileSize]: maxFileSizeString
              })
            )
          }
        </div>
        <div className="flex gap-1">
          {t(i18nConfig.bottomAcceptedFileTypes.key, {
            [i18nConfig.bottomAcceptedFileTypes.params.acceptedTypes]: acceptedFileTypesString
          })}
        </div>
      </div>
    </>
  );
}

export default FileUploader;
