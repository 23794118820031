import {
  Activity,
  AlertCircle,
  AlertTriangle,
  ArrowLeft,
  ArrowRight,
  Blocks,
  Book,
  Bot,
  BotOff,
  Calendar,
  Camera,
  CameraOff,
  Check,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  Clock,
  Eye,
  File as BlankFile,
  FileClock,
  Filter,
  Frown,
  Globe,
  Info,
  LineChart,
  Megaphone,
  Menu,
  MessageCirclePlus,
  Pencil,
  Plus,
  PlusCircle,
  Presentation,
  RefreshCw,
  Rocket,
  Search,
  SendHorizontal,
  Settings,
  Smile,
  Sparkles,
  Tag,
  Trash,
  Upload,
  User,
  UserRoundMinus,
  UserRoundPlus,
  Users,
  Wand2,
  X,
  Meh,
} from "lucide-react";

export type IconProps = React.HTMLAttributes<SVGElement>;

/**
 * Component that works as a wrapper for the icons from Lucide library and for custom icons.
 */
export const Icons = {
  Activity: Activity,
  Megaphone: Megaphone,
  AlertCircle: AlertCircle,
  AlertTriangle: AlertTriangle,
  ArrowLeft: ArrowLeft,
  ArrowRight: ArrowRight,
  Blocks: Blocks,
  Book: Book,
  Bot: Bot,
  BotOff: BotOff,
  Check: Check,
  ChevronLeft: ChevronLeft,
  ChevronRight: ChevronRight,
  ChevronsUpDown: ChevronsUpDown,
  Calendar: Calendar,
  Camera: Camera,
  CameraOff: CameraOff,
  Clock: Clock,
  Eye: Eye,
  File: BlankFile,
  FileClock: FileClock,
  Filter: Filter,
  Globe: Globe,
  HappyFace: Smile,
  Info: Info,
  LineChart: LineChart,
  NeutralFace: Meh,
  Menu: Menu,
  MessageCirclePlus: MessageCirclePlus,
  Pencil: Pencil,
  Plus: Plus,
  PlusCircle: PlusCircle,
  Presentation: Presentation,
  Refresh: RefreshCw,
  Rocket: Rocket,
  SadFace: Frown,
  Search: Search,
  SendHorizontal: SendHorizontal,
  Settings: Settings,
  Sparkles: Sparkles,
  Tag: Tag,
  Trash: Trash,
  User: User,
  Users: Users,
  Upload: Upload,
  UserRoundMinus: UserRoundMinus,
  UserRoundPlus: UserRoundPlus,
  Wand: Wand2,
  X: X,
  /**
   * Spinner icon.
   * Example of animated spinner:
   * @example
   * <Icons.Spinner className="w-8 h-8 animate-spin" />
   */
  Spinner: (props: IconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  ),
};
