import { useTranslation } from "react-i18next";
import { I18nConstants } from "@edutrackr/shared/constants";
import { I18nUtils } from "@edutrackr/shared/utils";
import { LanguageSelect } from "@edutrackr/ui/components";

export function StudentLanguageSelect() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage ?? I18nConstants.DEFAULT_LANGUAGE;

  const handleLanguageChange = (language: string) => {
    I18nUtils.changeLanguage(language);
    window.location.reload();
  }

  return (
    <LanguageSelect
      defaultLanguage={currentLanguage}
      onLanguageChange={handleLanguageChange}
    />
  );
}

export default StudentLanguageSelect;
