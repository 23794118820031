import { cn } from '@edutrackr/shared/utils';
import styles from './app-loader.module.css';

export interface AppLoaderProps {
  className?: string;
}

export function AppLoader(props: AppLoaderProps) {
  return (
    <div className={cn(
      "flex flex-col items-center justify-center",
      props.className
    )}>
      <div className={styles.loader}></div>
    </div>
  );
}

export default AppLoader;
