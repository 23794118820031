import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage,
  Input
} from '@edutrackr/ui/components';
import { useFormResolver } from '@edutrackr/shared/hooks';
import { useLoginMutation } from '../../hooks';
import { LoginFormValues, loginFormSchema } from '../../schemas/login-form.schema';


const defaultValues: LoginFormValues = {
  email: '',
  password: '',
  acceptTerms: false,
};

export interface LoginFormProps { }

export function LoginForm(props: LoginFormProps) {
  const { t } = useTranslation('auth', {
    keyPrefix: 'components.loginForm',
  });

  const resolver = useFormResolver(loginFormSchema());
  const form = useForm<LoginFormValues>({
    resolver,
    defaultValues,
  });

  const loginMutation = useLoginMutation();

  const onSubmit = (values: LoginFormValues) => {
    loginMutation.mutate(values);
  };

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <Form {...form}>
        <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('fields.email.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('fields.email.placeholder')}
                    type="email"
                    autoComplete="username"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('fields.password.label')}
                </FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    autoComplete="current-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="acceptTerms"
            render={({ field }) => (
              <FormItem>
                <div className="flex space-x-2">
                  <FormControl>
                    <Checkbox
                      className='mt-0.5'
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      {t('fields.acceptTerms.label')}
                    </FormLabel>
                    <FormDescription className='text-balance'>
                      {t('fields.acceptTerms.description')}
                    </FormDescription>
                  </div>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button className='w-full' type="submit" loading={loginMutation.isPending}>
            {t('buttons.login')}
          </Button>

        </form>
      </Form>
    </div>
  );
}

export default LoginForm;
