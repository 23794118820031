import { BaseApiResponse } from "@edutrackr/shared/types";
import { HttpClient } from "./http-client";

export abstract class BaseService {
  constructor(protected readonly httpClient: HttpClient) { }

  /**
   * Generate a dummy success response.
   */
  protected successResponse<T = unknown>(message: string, data: T = {} as T): BaseApiResponse<T> {
    return {
      success: true,
      statusCode: 200,
      message,
      data,
    };
  }

  /**
   * Generate a dummy error response.
   */
  protected errorResponse<T = unknown>(message: string, statusCode = 400): BaseApiResponse<T> {
    return {
      success: false,
      statusCode,
      message,
      error: {
        type: 'Error',
      },
    };
  }
}
