import { RouteObject } from "react-router-dom";
import { SessionsPage } from "./pages/sessions-page";
import { CurrentSessionPage } from "./pages/current-session-page";
import { PrivateRoute } from "@edutrackr/ui/components";
import { defaultRedirectConfig } from "../../shared/config";
import { StudentMainLayout } from "../../shared/components";
import { StudentMinimalLayout } from "../../shared/layouts";

const routes: RouteObject[] = [
  {
    path: "sessions",
    children: [
      { // Routes with default layout
        element: (
          <PrivateRoute redirectTo={defaultRedirectConfig.privateRoute}>
            <StudentMainLayout />
          </PrivateRoute>
        ),
        children: [
          {
            index: true,
            element: <SessionsPage />
          }
        ]
      },
      { // Routes with minimal layout
        element: (
          <PrivateRoute redirectTo={defaultRedirectConfig.privateRoute}>
            <StudentMinimalLayout />
          </PrivateRoute>
        ),
        children: [
          {
            path: "current/:sessionId",
            element: <CurrentSessionPage />
          }
        ]
      },
    ]
  }
];

export default routes;
