import { useTheme } from "next-themes"
import { Toaster as Sonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "light" } = useTheme() // TODO: Change them to system when adding dark mode support

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            "group toast group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          title: "group-[.toast]:text-foreground text-sm font-semibold w-100",
          description: "group-[.toast]:text-muted-foreground text-sm pt-1",
          actionButton:
            "group-[.toast]:h-8 group-[.toast]:shrink-0 group-[.toast]:items-center group-[.toast]:justify-center group-[.toast]:rounded-md group-[.toast]:border-reset group-[.toast]:bg-primary group-[.toast]:text-primary-foreground group-[.toast]:px-3 group-[.toast]:text-sm group-[.toast]:font-medium group-[.toast]:ring-offset-background group-[.toast]:transition-colors group-[.toast]:hover:bg-primary/90 group-[.toast]:focus:outline-none group-[.toast]:disabled:pointer-events-none group-[.toast]:disabled:opacity-50",
          cancelButton:
            "group-[.toast]:text-foreground group-[.toast]:h-8 group-[.toast]:shrink-0 group-[.toast]:items-center group-[.toast]:justify-center group-[.toast]:rounded-md group-[.toast]:border-reset group-[.toast]:bg-background group-[.toast]:bg-transparent group-[.toast]:px-3 group-[.toast]:text-sm group-[.toast]:font-medium group-[.toast]:ring-offset-background group-[.toast]:transition-colors group-[.toast]:hover:bg-secondary group-[.toast]:focus:outline-none group-[.toast]:disabled:pointer-events-none group-[.toast]:disabled:opacity-50",
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
