import { Outlet } from 'react-router-dom';
import { StudentBaseLayout } from './student-base-layout';

export function StudentMinimalLayout() {
  return (
    <StudentBaseLayout>
      <Outlet />
    </StudentBaseLayout>
  );
}
