export interface BaseLayoutProps {
  children: React.ReactNode;
}

export function BaseLayout({ children }: BaseLayoutProps) {
  return (
    <div className="flex flex-col min-h-screen-patched bg-gray-50 dark:bg-slate-900 dark:text-slate-200 bg-pattern-topography">
      {children}
    </div>
  );
}

export default BaseLayout;
