import { Card, ScrollArea } from "@edutrackr/ui/components";
import { ArrayUtils } from "@edutrackr/shared/utils";
import { SessionDetail } from "@edutrackr/services/common";
import SessionsListItem from "./sessions-list-item";
import { useTranslation } from 'react-i18next';

export interface SessionsListProps {
  items: SessionDetail[];
  selected: string | null;
  showActiveIndicator?: boolean;
  onSelect: (id: string) => void;
}

export function SessionsList(props: SessionsListProps) {
  const { t } = useTranslation('sessions', {
    keyPrefix: 'components.sessionsList',
  });
  return (
    <Card className="max-h-[400px]">
      <ScrollArea className="h-full">
        <div className="flex flex-col gap-2 p-4">
          {
            !ArrayUtils.isEmpty(props.items)
              ? props.items.map((item) => (
                <SessionsListItem
                  key={item.id}
                  item={item}
                  isSelected={props.selected === item.id}
                  onSelect={() => props.onSelect(item.id)}
                  showActiveIndicator
                />
              ))
              : (
                <div className="flex flex-col items-center justify-center h-full">
                  <p className="text-sm text-muted-foreground">
                  {t('card.notAvailableSessions.label')}
                  </p>
                </div>
              )
          }
        </div>
      </ScrollArea>
    </Card>
  );
}

export default SessionsList;
