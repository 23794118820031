import { cn } from "@edutrackr/shared/utils";

interface MainContentContainerProps {
  minimalMargin?: boolean;
  children: React.ReactNode;
}

/**
 * Main content container component.
 */
export const MainContentContainer = ({
  minimalMargin = false,
  children
}: MainContentContainerProps) => {

  return (
    <main className="@container/main flex-grow">
      <div className="flex-grow p-6 @3xl/main:py-8 @5xl/main:px-20">
        <div className={cn(
          "max-w-7xl mx-auto px-2 @5xl/main:px-14",
            !minimalMargin && "pb-6 @xl/main:pt-3"
          )}>
          {children}
        </div>
      </div>
    </main>
  )
};
