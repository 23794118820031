import { StorageKeys } from "@edutrackr/shared/enums";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useCallback, useEffect } from "react";

/**
 * Logout event handler to synchronize logout across tabs.
 */
export const useLogout = (onLogout: () => void) => {
  const [logoutEvent, setLogoutEvent] = useLocalStorage<string | null>(StorageKeys.LogoutEvent, null);

  const logout = useCallback(() => {
    setLogoutEvent(String(Date.now()));
    setTimeout(() => {
      setLogoutEvent(null);
    }, 10);
  }, [setLogoutEvent]);

  // Listen for logout events (from other tabs)
  useEffect(() => {
    if (logoutEvent) {
      onLogout();
    }
  }, [onLogout, logoutEvent]);

  return logout;
}
