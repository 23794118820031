import { useAuth } from '@edutrackr/shared/hooks';
import { MainLayout } from '@edutrackr/ui/layouts';
import { StudentTopNavbar } from '../components/student-top-navbar';

export interface SudentBaseLayoutProps {
  children: React.ReactNode;
}

export function StudentBaseLayout({ children }: SudentBaseLayoutProps) {
  const { user, logout } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <MainLayout>
      <StudentTopNavbar user={user} onLogout={logout} />
      {children}
    </MainLayout>
  );
}
