export interface ChatMessage {
  /** The role of the message. */
  role: MessageRole;

  /** The message content. */
  content: string;

  /** The references to the course resources.
   * Not all messages have references (e.g. user messages).
  */
  references?: MessageContentReference[];
}

/** Chat message role. */
export enum MessageRole {
  User = "user",
  Assistant = "assistant",
}

/** Chat message content reference. */
export interface MessageContentReference {
  /** The name of the reference (usually the name of the file or the resource). */
  source: string;

  /** The text of the reference. */
  text: string;

  /** The course resource ID. */
  course_resource_id: string;

  /** The blob ID (can be converted to a URL). */
  blob_id: string;
}
