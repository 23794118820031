import { TeacherQueryKeys } from "@edutrackr/shared/enums";
import { commonAssistantService } from "../../../shared/services";

import { useQuery } from "@tanstack/react-query";

export const useLastSessionChatQuery = (sessionId: string) => {
  const sessionPlanQuery = useQuery({
    queryKey: [TeacherQueryKeys.GetLastSessionChat, sessionId],
    queryFn: async (context) => {
      const { data } = await commonAssistantService.getLastSessionChatContent({
        sessionId: sessionId,
      });
      return data?.chatSession;
    },
    refetchOnWindowFocus: false,
  });

  return sessionPlanQuery;
};
