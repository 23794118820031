import { BaseService, HttpClient } from "@edutrackr/shared/utils";
import { BaseApiResponse } from "@edutrackr/shared/types";
import { LoginRequest, LoginResponse, RefreshTokenRequest } from "./types/auth";

export class AuthService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  login(request: LoginRequest): Promise<BaseApiResponse<LoginResponse>> {
    return this.httpClient.post<BaseApiResponse<LoginResponse>>('/auth/login', request);
  }

  refreshToken(request: RefreshTokenRequest): Promise<BaseApiResponse<LoginResponse>> {
    return this.httpClient.post<BaseApiResponse<LoginResponse>>('/auth/refresh', request);
  }

}

export class DummyAuthService extends AuthService {

  private static authData: LoginResponse = {
    activeRole: 'unknown',
    tokens: {
      accessToken: 'accessToken',
      refreshToken: 'refreshToken',
      accessTokenExpireTime: 3600,
      refreshTokenExpireTime: 3600,
    },
    user: {
      id: "1",
      firstName: 'John',
      lastName: 'Doe',
      email: 'jhon.doe@edutrackr.com',
      roles: ['unknown'],
    }
  };

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login(request: LoginRequest): Promise<BaseApiResponse<LoginResponse>> {
    return Promise.resolve(this.successResponse("Success", DummyAuthService.authData));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshToken(request: RefreshTokenRequest): Promise<BaseApiResponse<LoginResponse>> {
    return Promise.resolve(this.successResponse("Success", DummyAuthService.authData));
  }
}
