import { Navigate, RouteObject } from "react-router-dom";
import RedirectCurrentSessionPage from "./pages/redirect-current-session-page";

const routes: RouteObject[] = [
  {
    path: "link",
    children: [
      { index: true, element: <Navigate to="/" replace={true} /> },
      { path: "join/:sessionId", element: <RedirectCurrentSessionPage /> },
    ]
  }
];

export default routes;
