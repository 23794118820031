import { BaseService, HttpClient } from "@edutrackr/shared/utils";
import { BaseApiResponse, BasePaginatedResult } from "@edutrackr/shared/types";
import { GetSessionByIdRequest, GetUserSessionsRequest, SessionDetail } from "./types/session";

export class SessionService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getSessionById(request: GetSessionByIdRequest): Promise<BaseApiResponse<SessionDetail>> {
    return this.httpClient.get<BaseApiResponse<SessionDetail>>('/session/GetById', request);
  }

  getUserSessions(request: GetUserSessionsRequest): Promise<BaseApiResponse<BasePaginatedResult<SessionDetail>>> {
    return this.httpClient.get<BaseApiResponse<BasePaginatedResult<SessionDetail>>>('/session/getUserSessionsPaginated', request);
  }

}
