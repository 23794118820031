import { ChatMessage, MessageRole } from "@edutrackr/shared/types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sampleMessages: ChatMessage[] = [
  {
    content: "I need help with generating a plan. Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, magnam ipsa? Nihil tenetur perferendis ad quia magni nostrum, error dolorum.",
    role: MessageRole.User,
    references: [],
  },
  {
    content: "Sure, I can help with that",
    role: MessageRole.Assistant,
    references: [
      {
        source: "file1.pdf",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.\nLorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        course_resource_id: "",
        blob_id: ""
      },
      {
        source: "file2-new-copy.pdf",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        course_resource_id: "",
        blob_id: ""
      },
      {
        source: "Introduction to Artificial Intelligence (Short Version).pdf",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        course_resource_id: "",
        blob_id: ""
      }
    ],
  },
  {
    content: "Thank\nyou",
    role: MessageRole.User,
    references: [],
  },
  ...Array.from({ length: 20 }, (_, i) => ({
    content: `Message ${i + 1}`,
    role: i % 2 === 0 ? MessageRole.Assistant as const : MessageRole.User as const,
    references: [],
  })),
];

export class ChatUtils {

  static getInitialMessages(welcomeMessageContent: string): ChatMessage[] {
    return [
      {
        content: welcomeMessageContent,
        role: MessageRole.Assistant,
        references: [],
      } satisfies ChatMessage,
      // ...sampleMessages,
    ];
  }

}
