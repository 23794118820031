import { Navigate, useParams } from "react-router-dom";

export interface RedirectCurrentSessionPageProps { }

export function RedirectCurrentSessionPage(props: RedirectCurrentSessionPageProps) {
  const { sessionId } = useParams();

  return (
    <Navigate to={`/sessions/current/${sessionId}`} replace={true} />
  );
}

export default RedirectCurrentSessionPage;
