export const I18nConstants = {
  DEFAULT_LANGUAGE: 'en-US',
  SUPPORTED_LANGUAGES: ['en-US', 'es-ES'] as const,
  DEFAULT_NAMESPACE: 'common' as const,
};

export type SupportedLanguage = typeof I18nConstants.SUPPORTED_LANGUAGES[number];

export const LanguageOptions: Record<SupportedLanguage, string> = {
  'en-US': 'English',
  'es-ES': 'Español'
};

/**
 * Common i18n keys used across libraries (to ensure type safety and consistency).
 */
export const I18nCommonKeys = {
  labels: {
    remove: 'labels.remove',
  },
  messages: {
    unknownError: 'messages.unknownError',
    failedQuery: 'messages.failedQuery',
  },
  components: {
    userMenu: {
      logout: 'components.userMenu.logout'
    },
    chat: {
      chatMessage: {
        referencesPrefix: 'components.chat.chatMessage.referencesPrefix'
      },
      loadingMessage: {
        message: 'components.chat.loadingMessage.message'
      },
      overlays: {
        loading: {
          message: 'components.chat.overlays.loading.message'
        },
        unavailable: {
          message: 'components.chat.overlays.unavailable.message'
        },
        error: {
          message: 'components.chat.overlays.error.message',
          buttons: {
            tryAgain: 'components.chat.overlays.error.buttons.tryAgain'
          }
        }
      }
    },
    popoverCalendar: {
      selectDate: 'components.popoverCalendar.selectDate'
    },
    timePicker: {
      hours: 'components.timePicker.hours',
      minutes: 'components.timePicker.minutes',
      seconds: 'components.timePicker.seconds'
    },
    dataTablePagination: {
      recordsPerPage: 'components.dataTablePagination.recordsPerPage',
      pageOf: {
        key: 'components.dataTablePagination.pageOf',
        params: {
          pageNumber: 'pageNumber',
          totalPages: 'totalPages'
        }
      },
      previousPage: 'components.dataTablePagination.previousPage',
      nextPage: 'components.dataTablePagination.nextPage'
    },
    dataTableRecords: {
      noRecords: "components.dataTableRecords.noRecords"
    },
    fileUploader: {
      labelIdle: "components.fileUploader.labelIdle",
      labelMaxFileSizeExceededTitle: "components.fileUploader.labelMaxFileSizeExceededTitle",
      labelMaxFileSizeExceededMessage: {
        key: "components.fileUploader.labelMaxFileSizeExceededMessage",
        params: {
          maxFileSize: "maxFileSize"
        }
      },
      labelFileTypeNotAllowedTitle: "components.fileUploader.labelFileTypeNotAllowedTitle",
      labelFileTypeNotAllowedMessage: {
        key: "components.fileUploader.labelFileTypeNotAllowedMessage",
        params: {
          acceptedTypes: "acceptedTypes"
        }
      },
      bottomSingleFileMessage: {
        key: "components.fileUploader.bottomSingleFileMessage",
        params: {
          maxFileSize: "maxFileSize"
        }
      },
      bottomMultipleFilesMessage: {
        key: "components.fileUploader.bottomMultipleFilesMessage",
        params: {
          maxFiles: "maxFiles",
          maxFileSize: "maxFileSize"
        }
      },
      bottomAcceptedFileTypes: {
        key: "components.fileUploader.bottomAcceptedFileTypes",
        params: {
          acceptedTypes: "acceptedTypes"
        }
      }
    },
    combobox: {
      loading: "components.combobox.loading",
      empty: "components.combobox.empty",
      triggerButton: "components.combobox.triggerButton",
    }
  },
  hooks: {
    useRefreshSession: {
      error: {
        title: "hooks.useRefreshSession.error.title",
        message: "hooks.useRefreshSession.error.message"
      }
    }
  },
  services: {
    common: {
      formatters: {
        sessionNoScheduledTime: "services.common.formatters.sessionNoScheduledTime",
        sessionDetailPrivate: {
          realStartTimeLabel: {
            key: "services.common.formatters.sessionDetailPrivate.realStartTimeLabel",
            params: {
              startTime: "startTime",
              startTimeDistance: "startTimeDistance"
            }
          },
          scheduledStartTimeLabel: {
            beforeScheduleTime: {
              key: "services.common.formatters.sessionDetailPrivate.scheduledStartTimeLabel.beforeScheduleTime",
              params: {
                label: "label"
              }
            },
            afterScheduleTime: {
              key: "services.common.formatters.sessionDetailPrivate.scheduledStartTimeLabel.afterScheduleTime",
              params: {
                label: "label",
                scheduledStartTimeDistance: "scheduledStartTimeDistance"
              }
            }
          },
        },
        sessionDetail: {
          sessionTypeLabel: {
            notEnabled: "services.common.formatters.sessionDetail.sessionTypeLabel.notEnabled",
            active: "services.common.formatters.sessionDetail.sessionTypeLabel.active",
            scheduled: "services.common.formatters.sessionDetail.sessionTypeLabel.scheduled"
          }
        },
        sessionMinimalPrivate: {
          realStartTimeTooltip: {
            key: "services.common.formatters.sessionMinimalPrivate.realStartTimeTooltip",
            params: {
              startTime: "startTime"
            }
          },
          realStartTimeLabel: {
            key: "services.common.formatters.sessionMinimalPrivate.realStartTimeLabel",
            params: {
              startTimeDistance: "startTimeDistance"
            }
          },
          scheduledStartTimeTooltip: {
            key: "services.common.formatters.sessionMinimalPrivate.scheduledStartTimeTooltip",
            params: {
              scheduledStartTime: "scheduledStartTime"
            }
          },
          scheduledStartTimeLabel: {
            beforeScheduleTime: "services.common.formatters.sessionMinimalPrivate.scheduledStartTimeLabel.beforeScheduleTime",
            afterScheduleTime: {
              key: "services.common.formatters.sessionMinimalPrivate.scheduledStartTimeLabel.afterScheduleTime",
              params: {
                scheduledStartTimeDistance: "scheduledStartTimeDistance"
              }
            }
          }
        },
      }
    }
  }
} as const;
