import { Category, FaceLandmarker } from "@mediapipe/tasks-vision";
import { create } from "zustand";
import { MetricAnalysisSlice, TimerSlice, createMetricAnalysisSlice, createTimerSlice } from "./slices";

interface FaceLandmarkerStore {
  faceLandmarker: FaceLandmarker | null;
  setFaceLandmarker: (faceLandmarker: FaceLandmarker | null) => void;
}

export const useFaceLandmarkerStore = create<FaceLandmarkerStore>((set) => ({
  faceLandmarker: null,
  setFaceLandmarker: (faceLandmarker) => set({ faceLandmarker }),
}));

interface AIResultsStore {
  blendshapeCategories: Category[];
  intervalBlinkCount: number;
  intervalYawnCount: number;
  totalBlinkCount: number;
  totalYawnCount: number;
  isFaceDetected: boolean;

  setBlendshapeCategories: (blendshapeCategories: Category[]) => void;
  setFaceDetected: (isFaceDetected: boolean) => void;
  incrementBlinkCount: () => void;
  incrementYawnCount: () => void;
  resetIntervalCounts: () => void;
  reset: () => void;
}

export const useAIResultsStore = create<AIResultsStore>((set, get) => ({
  blendshapeCategories: [],
  intervalBlinkCount: 0,
  intervalYawnCount: 0,
  totalBlinkCount: 0,
  totalYawnCount: 0,
  isFaceDetected: false,

  setBlendshapeCategories: (blendshapeCategories) => set({ blendshapeCategories }),
  setFaceDetected: (isFaceDetected) => set({ isFaceDetected }),
  incrementBlinkCount: () => set({
    intervalBlinkCount: get().intervalBlinkCount + 1,
    totalBlinkCount: get().totalBlinkCount + 1,
  }),
  incrementYawnCount: () => set({
    intervalYawnCount: get().intervalYawnCount + 1,
    totalYawnCount: get().totalYawnCount + 1,
  }),
  resetIntervalCounts: () => set({
    intervalBlinkCount: 0,
    intervalYawnCount: 0,
  }),
  reset: () => set({
    blendshapeCategories: [],
    intervalBlinkCount: 0,
    intervalYawnCount: 0,
    totalBlinkCount: 0,
    totalYawnCount: 0,
    isFaceDetected: false,
  }),
}));


/** AI analysis stores */

// Blinks

type BlinkTimerStore = TimerSlice;
export const useBlinkTimerStore = create<BlinkTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type BlinkAnalysisStore = MetricAnalysisSlice;
export const useBlinkAnalysisStore = create<BlinkAnalysisStore>((...params) => ({
  ...createMetricAnalysisSlice(...params),
}));


// Yawns

type YawnTimerStore = TimerSlice;
export const useYawnTimerStore = create<YawnTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type YawnAnalysisStore = MetricAnalysisSlice;
export const useYawnAnalysisStore = create<YawnAnalysisStore>((...params) => ({
  ...createMetricAnalysisSlice(...params),
}));
