import { useState, useCallback } from "react";
import { AppConstants } from "@edutrackr/shared/constants";


/**
 * Session timeout handler.
 */
export const useSessionTimeout = () => {
  const [sessionTimeout, setSessionTimeout] = useState<NodeJS.Timeout | null>(null);

  const schedule = useCallback((onTimeout: () => void, expiresIn: number) => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    const waitTimeInMs = (expiresIn - AppConstants.securiry.TIME_BEFORE_EXPIRATION) * 1000;
    setSessionTimeout(
      setTimeout(() => {
        onTimeout();
      }, waitTimeInMs)
    );
  }, [sessionTimeout]);

  const clear = useCallback(() => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
    setSessionTimeout(null);
  }, [sessionTimeout]);

  return {
    schedule,
    clear,
  };
}
