import { I18nCommonKeys } from "@edutrackr/shared/constants";
import { Skeleton } from "@edutrackr/ui/components";
import { useTranslation } from "react-i18next";

export function LoadingChatMessage() {
  const { t } = useTranslation();

  return (
    <div
      className="flex gap-2 p-2 rounded-lg w-[80%] border border-gray-200 self-start rounded-tl-none"
    >
      <div className="space-y-2 w-full">
        <Skeleton className="h-4 w-11/12" />
        <Skeleton className="h-4 w-9/12" />
        <p className="text-xs text-gray-400">{t(I18nCommonKeys.components.chat.loadingMessage.message)}</p>
      </div>
    </div>
  );
}
