import { JsonUtils } from "./json-utils";

export class LocalStorageUtils {

  public static getItem<T>(key: string): T | null {
    const data = localStorage.getItem(key);
    return data
      ? JsonUtils.tryDeserialize<T>(data)
      : null;
  }

  public static setItem<T>(key: string, data: T): void {
    const json = JsonUtils.trySerialize(data);
    if (!json) {
      throw new Error("Failed to serialize data to JSON");
    }
    localStorage.setItem(key, json);
  }

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static clear(): void {
    localStorage.clear();
  }

}
