// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import { useState, useEffect } from "react";

const mapEmotionToSpanish = (emotion) => {
  switch (emotion.toLowerCase()) {
    case "angry":
      return "Enojo";
    case "disgust":
      return "Disgusto";
    case "fear":
      return "Miedo";
    case "happy":
      return "Felicidad";
    case "sad":
      return "Tristeza";
    case "surprise":
      return "Sorpresa";
    case "neutral":
      return "Neutral";
    default:
      return "---";
  }
}


const DominantComponent = () => {
  const [dominantEmotion, setDominantEmotion] = useState("");

  useEffect(() => {
    bindEvents();
  }, []);

  function bindEvents() {
    window.addEventListener("CY_FACE_EMOTION_RESULT", (evt) => {
      setDominantEmotion(evt.detail.output.dominantEmotion || "");
    });
  }
  return (
    <div >
      <p style={{ fontSize: "16px" }}>Dominant Emotion:</p>
      <p style={{ textAlign: "center", textTransform: "capitalize", fontWeight: "bold", lineHeight: "1.5rem" }}>
        { dominantEmotion || "---" }
        {/* {mapEmotionToSpanish(dominantEmotion)} */}
      </p>
    </div>
  );
};

export default DominantComponent;
