import { Card } from "@edutrackr/ui/components";
import { logo } from "@edutrackr/ui/assets";
import { LoginForm } from "../../components/login-form";
import { AppConstants } from "@edutrackr/shared/constants";
import { useTranslation } from "react-i18next";

export interface LoginPageProps { }

export function LoginPage(props: LoginPageProps) {
  const { t } = useTranslation('auth', {
    keyPrefix: 'pages.login',
  });

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">

      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-10 w-auto"
          src={logo}
          alt={AppConstants.COMPANY_NAME}
        />
      </div>

      <div className="flex flex-col space-y-2 text-center sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-2xl font-poppins font-medium leading-9 tracking-tight text-gray-900">
          {t('title')}

        </h2>
        <p className="text-sm text-muted-foreground">
          {t('subtitle')}
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
        <Card className="px-6 py-8 shadow-md">
          <LoginForm />
        </Card>
      </div>

    </div>
  );
}


export default LoginPage;
