import { version } from '@edutrackr/package.json';

export const AppConstants = {
  COMPANY_NAME: 'Edutrackr',
  CURRENT_VERSION: version as string,
  securiry: {
    /** Seconds before the access token expires to refresh it. */
    TIME_BEFORE_EXPIRATION: 60,
    MIN_PASSWORD_LENGTH: 8,
  },
};
