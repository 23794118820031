import { AuthMapper } from '@edutrackr/services/common';
import { AuthUser, NavItem } from '@edutrackr/shared/types';
import { logo } from '@edutrackr/ui/assets';
import { AppConstants } from '@edutrackr/shared/constants';
import { TopNavbar, UserMenu } from '@edutrackr/ui/components';
import { StudentLanguageSelect } from './student-language-select';

interface StudentTopNavbarProps {
  user: AuthUser;
  onLogout: () => void;
}

export function StudentTopNavbar(props: StudentTopNavbarProps) {
  const navigation: NavItem[] = [];

  const userInfo = AuthMapper.authUserToUserInfo(props.user);

  return (
    <TopNavbar
      company={AppConstants.COMPANY_NAME}
      logo={logo}
      navigation={navigation}
      rightContent={<>
        <StudentLanguageSelect />
        <UserMenu user={userInfo} onLogout={props.onLogout} />
      </>}
    />
  );
}

export default StudentTopNavbar;
