import { useNavigate } from 'react-router-dom';
import { NavItem, CurrentUserInfo } from "@edutrackr/shared/types";
import { ArrayUtils } from '@edutrackr/shared/utils';
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Button } from "../button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "../dropdown-menu";
import { useTranslation } from 'react-i18next';
import { I18nCommonKeys } from '@edutrackr/shared/constants';


export interface UserMenuProps {
  user: CurrentUserInfo;
  menuItems?: NavItem[];
  onLogout?: () => void;
}

export function UserMenu(props: UserMenuProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full focus-visible:ring-slate-300">
          <Avatar className="size-8">
            <AvatarImage src={props.user.avatarUrl} alt={props.user.name} />
            <AvatarFallback>{props.user.initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{props.user.name}</p>
            <p className="text-xs leading-none text-muted-foreground">
              {props.user.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {props.menuItems && !ArrayUtils.isEmpty(props.menuItems) && (
          <>
            <DropdownMenuGroup>
              {props.menuItems.map((item) => (
                <DropdownMenuItem key={item.label} onSelect={() => handleNavigate(item.path)}>
                  {item.icon}
                  {item.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
          </>
        )}
        <DropdownMenuItem onSelect={props.onLogout}>
          {t(I18nCommonKeys.components.userMenu.logout)}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default UserMenu;
