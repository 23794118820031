import { create } from "zustand";
import { TimerSlice, createTimerSlice } from "./slices";

type IdleTimerStore = TimerSlice;
export const useIdleTimerStore = create<IdleTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type PresenceIntervalTimerStore = TimerSlice;
export const usePresenceIntervalTimerStore = create<PresenceIntervalTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type IntervalTimerStore = TimerSlice;
export const useIntervalTimerStore = create<IntervalTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type VideoWithoutPauseTimerStore = TimerSlice;
export const useVideoWithoutPauseTimerStore = create<VideoWithoutPauseTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

type VideoTimerStore = TimerSlice;
export const useVideoTimerStore = create<VideoTimerStore>((...params) => ({
  ...createTimerSlice(...params),
}));

interface CameraStatusStore {
  isCameraRunning: boolean;
  setCameraStatus: (status: boolean) => void;
}

export const useCameraStatusStore = create<CameraStatusStore>((set) => ({
  isCameraRunning: false,
  setCameraStatus: (status) => set(() => ({ isCameraRunning: status })),
}));

interface VideoRecorderStore {
  videoBlob: Blob | null;
  videoStream: MediaStream | null;
  mediaRecorder: MediaRecorder | null;
  tempMediaRecorder: MediaRecorder | null;
  tempRecordedChunk: Blob | null;

  setVideoBlob: (blob: Blob | null) => void;
  setVideoStream: (stream: MediaStream | null) => void;
  setMediaRecorder: (mediaRecorder: MediaRecorder | null) => void;
  setTempMediaRecorder: (mediaRecorder: MediaRecorder | null) => void;
  setTempRecordedChunk: (chunk: Blob | null) => void;
  reset: () => void;
}
export const useVideoRecorderStore = create<VideoRecorderStore>((set) => ({
  videoBlob: null,
  videoStream: null,
  mediaRecorder: null,
  tempMediaRecorder: null,
  tempRecordedChunk: null,

  setVideoBlob: (blob) => set(() => ({ videoBlob: blob })),
  setVideoStream: (stream) => set(() => ({ videoStream: stream })),
  setMediaRecorder: (mediaRecorder) => set(() => ({ mediaRecorder })),
  setTempMediaRecorder: (mediaRecorder) => set(() => ({ tempMediaRecorder: mediaRecorder })),
  setTempRecordedChunk: (chunk) => set(() => ({ tempRecordedChunk: chunk })),
  reset: () => set(() => ({
    videoBlob: null,
    videoStream: null,
    mediaRecorder: null,
    tempMediaRecorder: null,
    tempRecordedChunk: null,
  })),
}));
