import React from "react";
import { useTranslation } from "react-i18next";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { DropdownItem } from "@edutrackr/shared/types";
import { I18nCommonKeys } from "@edutrackr/shared/constants";
import { Button } from "../button";
import { Command, CommandGroup, CommandItem, CommandList } from "../command";
import { Icons } from "../icons";
import { Input } from "../input";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { ScrollArea } from "../scroll-area";
import { cn } from "@edutrackr/shared/utils";
import styles from './combobox.module.css';

export interface ComboboxLoadingProps {
  children?: React.ReactNode;
}

const ComboboxLoading = ({
  children,
}: ComboboxLoadingProps) => {
  const { t } = useTranslation();
  const translatedChildren = children || t(I18nCommonKeys.components.combobox.loading);

  return (
    <div className="flex items-center justify-center gap-2 pt-3 p-2 text-xs text-muted-foreground">
      <Icons.Spinner className='w-4 h-4 animate-spin' />
      <span>{translatedChildren}</span>
    </div>
  );
};

export interface ComboboxEmptyProps {
  children?: React.ReactNode;
}

const ComboboxEmpty = ({
  children,
}: ComboboxEmptyProps) => {
  const { t } = useTranslation();
  const translatedChildren = children || t(I18nCommonKeys.components.combobox.empty);

  return (
    <div className="flex items-center justify-center pt-3 p-2 text-xs text-muted-foreground">
      <span>{translatedChildren}</span>
    </div>
  )
};

interface ComboboxTriggerProps {
  children: React.ReactNode;
}

const ComboboxTrigger = (props: ComboboxTriggerProps) => (
  <PopoverTrigger asChild>
    {props.children}
  </PopoverTrigger>
);

interface ComboboxTriggerButtonProps {
  /**
   * Label for the trigger button.
   */
  label?: string;

  /**
   * Classes for the trigger button (ideal to customize the width).
   */
  className?: string;

  /**
   * The selected option.
   */
  selectedOption: DropdownItem | null;
}

/**
 * Combobox trigger button (ideal for uncontrolled comboboxes).
 */
const ComboboxTriggerButton = React.forwardRef<HTMLButtonElement, ComboboxTriggerButtonProps>(
  ({
    label,
    selectedOption,
    className,
    ...props
  }, ref) => {
    const { t } = useTranslation();
    const translatedLabel = label || t(I18nCommonKeys.components.combobox.triggerButton);

    return (
      <Button
        type="button"
        variant="outline"
        role="combobox"
        className={cn(
          "w-full justify-between font-normal",
          !selectedOption && "text-muted-foreground",
          className
        )}
        ref={ref}
        {...props}
      >
        {
          selectedOption
            ? selectedOption.label
            : translatedLabel
        }
        <Icons.ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    )
  }
);

interface ComboboxInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

const ComboboxInput = (props: ComboboxInputProps) => (
  <div className="flex items-center gap-2 border-border border-b px-3">
    <Icons.Search className="h-4 w-4 text-muted-foreground" />
    <Input
      placeholder={props.placeholder}
      className="bg-transparent p-0 text-sm border-none rounded-none outline-none placeholder:text-muted-foreground focus-visible:ring-0 focus-visible:ring-offset-0"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
  </div>
);

interface ComboboxListItemIndicatorProps {
  loading?: boolean;
  selected?: boolean;
}

const ComboboxListItemIndicator = (props: ComboboxListItemIndicatorProps) => {
  if (props.loading) {
    return (
      <Icons.Spinner
        className="mr-2 h-4 w-4 animate-spin"
      />
    );
  }

  if (props.selected) {
    return (
      <Icons.Check
        className="mr-2 h-4 w-4"
      />
    );
  }

  return <div className="w-4 h-4 mr-2" />;
};

interface ComboboxListItemProps {
  item: DropdownItem;
  selected: boolean;
  onSelect: (item: DropdownItem) => void;
}

const ComboboxListItem = ({
  item,
  selected,
  ...props
}: ComboboxListItemProps) => {
  return (
    <CommandItem
      value={item.value}
      disabled={item.disabled}
      onSelect={() => props.onSelect(item)}
    >
      <ComboboxListItemIndicator loading={item.loading} selected={selected} />
      <div className="flex flex-col">
        {item.label}
        {item.description && (
          <span className="text-xs text-muted-foreground">
            {item.description}
          </span>
        )}
      </div>
    </CommandItem>
  );
};

interface ComboboxListWrapperProps {
  children: React.ReactNode;
}

const ComboboxListWrapper = (props: ComboboxListWrapperProps) => (
  <ScrollArea className="max-h-[120px] flex flex-col">
    <CommandList className="max-h-none overflow-x-visible overflow-y-visible">
      <CommandGroup>
        {props.children}
      </CommandGroup>
    </CommandList>
  </ScrollArea>
);

interface ComboboxListProps {
  items: DropdownItem[];
  selectedItem: DropdownItem | null;
  onItemSelected: (value: DropdownItem) => void;
}

const ComboboxList = (props: ComboboxListProps) => {
  return (
    <ComboboxListWrapper>
      {
        props.items.map(option => (
          <ComboboxListItem
            key={option.value}
            item={option}
            selected={option.value === props.selectedItem?.value}
            onSelect={props.onItemSelected}
          />
        ))
      }
    </ComboboxListWrapper>
  );
}

interface MultiComboboxListProps {
  items: DropdownItem[];
  selectedItems: DropdownItem[];
  onItemSelected: (value: DropdownItem) => void;
}

const MultiComboboxList = (props: MultiComboboxListProps) => {
  return (
    <ComboboxListWrapper>
      {
        props.items.map(option => (
          <ComboboxListItem
            key={option.value}
            item={option}
            selected={props.selectedItems.some(item => item.value === option.value)}
            onSelect={props.onItemSelected}
          />
        ))
      }
    </ComboboxListWrapper>
  );
}

interface ComboboxContentProps {
  /**
   * Classes for the combobox popover content (ideal to customize the width when the combobox trigger is small).
   */
  className?: string;
  children: React.ReactNode;
  align?: PopoverContentProps['align'];
}

const ComboboxContent = (props: ComboboxContentProps) => (
  <PopoverContent
    className={cn(
      styles.comboboxContent,
      "p-0",
      props.className
    )}
    align={props.align}
    // We need to stop propagation of these events to enable scrolling inside the popover (see https://github.com/radix-ui/primitives/issues/1159)
    onWheel={(event) => event.stopPropagation()}
    onTouchMove={(event) => event.stopPropagation()}
  >
    <Command>
      {props.children}
    </Command>
  </PopoverContent>
);

export interface ComboboxProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  children: React.ReactNode;
}

const Combobox = (props: ComboboxProps) => {
  return (
    <Popover open={props.open} onOpenChange={props.onOpenChange}>
      {props.children}
    </Popover>
  );
}

export {
  Combobox,
  ComboboxContent,
  ComboboxEmpty,
  ComboboxInput,
  ComboboxList,
  ComboboxLoading,
  ComboboxTrigger,
  ComboboxTriggerButton,
  MultiComboboxList
};
