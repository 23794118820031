import { InferenceDevice } from "@edutrackr/shared/enums";

export const AIConstants = {
  DEFAULT_INFERENCE_DEVICE: InferenceDevice.GPU,
  RUNNING_MODE: "VIDEO" as const,
  drawResults: {
    TIME: false,
    LANDMARKS: true,
    ANNOTATIONS: false,
  },
  videoCapture: {
    /** The maximum number of seconds that a video should last. */
    MAX_VIDEO_DURATION: 10,
    /** The maximum number of seconds that the use can be idle. */
    MAX_IDLE_DURATION: 5,
    /** The interval in seconds to update the user presence. */
    PRESENCE_INTERVAL_DURATION: 5,
    /** The video constraints to use when capturing video. */
    VIDEO_CONSTRAINTS: {
      facingMode: "user",
      width: 1280,
      height: 720,
    } satisfies MediaTrackConstraints,
    DEFAULT_VIDEO_NAME: "video.webm",
  },
  metrics: {
    blinking: {
      /** The minium eye blink confidence level to be considered as blinking (0.0 - 1.0). Same for both eyes. */
      MIN_BLINK_CONFIDENCE: 0.5,
      /** The minimum duration to be considered as blinking (in seconds, use float to represent milliseconds). */
      MIN_DURATION: 0.01,
    },
    yawning: {
      /** The minimum yawn confidence level to be considered as yawning (0.0 - 1.0). */
      MIN_YAWN_CONFIDENCE: 0.5,
      /** The minimum duration to be considered as yawning (in seconds). */
      MIN_DURATION: 2,
    },
  }
};
