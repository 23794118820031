import { StateCreator } from "zustand";

export interface MetricAnalysisSlice {
  /**
   * Whether a metric is active.
   */
  isMetricActive: boolean;

  setIsMetricActive: (isMetricActive: boolean) => void;
  reset: () => void;
}

/**
 * Creates a metric analysis slice.
 */
export const createMetricAnalysisSlice: StateCreator<MetricAnalysisSlice> = (set) => ({
  isMetricActive: false,

  setIsMetricActive: (isMetricActive) => set({ isMetricActive }),
  reset: () => set({ isMetricActive: false }),
});
