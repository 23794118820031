import { useTranslation } from "react-i18next";
import { AppLoader } from "@edutrackr/ui/components";
import styles from "./chat-loading-overlay.module.css";
import { I18nCommonKeys } from "@edutrackr/shared/constants";

export function ChatLoadingOverlay() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col flex-grow items-center justify-center gap-3 px-4 py-3">
      <AppLoader />
      <p className="text-muted-foreground text-sm mt-2">
        {t(I18nCommonKeys.components.chat.overlays.loading.message)}
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
        <span className={styles.dot}>.</span>
      </p>
    </div>
  );
}
