import { Select, SelectTrigger, SelectContent, SelectItem } from "../select";
import { Icons } from "../icons";
import { LanguageOptions } from "@edutrackr/shared/constants";
import { SelectOption } from "@edutrackr/shared/types";

export interface LanguageSelectProps {
  defaultLanguage: string;
  onLanguageChange: (language: string) => void;
}

const languageOptions = Object.entries(LanguageOptions).map(
  ([value, label]) => ({ value, label } satisfies SelectOption)
);

export function LanguageSelect(props: LanguageSelectProps) {
  return (
    <Select onValueChange={props.onLanguageChange} value={props.defaultLanguage}>
      <SelectTrigger>
        <Icons.Globe className="w-4 h-4 mr-2" />
      </SelectTrigger>
      <SelectContent align="end">
        {
          languageOptions.map((option) => (
            <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
          ))
        }
      </SelectContent>
    </Select>
  );
}

export default LanguageSelect;
