import { AppRoot } from '@edutrackr/ui/components';
import { AppRouterProvider } from '@edutrackr/shared/providers';
import { useAppReady, useLanguageChange } from '@edutrackr/shared/hooks';
import { router } from './routes';
import { configureLocale } from './shared/i18n';

export function App() {
  const ready = useAppReady();
  useLanguageChange(configureLocale);

  return (
    <AppRoot loading={!ready}>
      <AppRouterProvider router={router} />
    </AppRoot>
  );
}

export default App;
