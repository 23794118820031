import { CommonQueryKeys } from "@edutrackr/shared/enums";
import { useQuery } from "@tanstack/react-query";
import { sessionService } from "../../../shared/services";

export const useSessionQuery = (sessionId: string) => {
  const sessionQuery = useQuery({
    queryKey: [CommonQueryKeys.GetSessionById, sessionId],
    queryFn: async () => {
      const { data } = await sessionService.getSessionById({
        id: sessionId,
      });
      return data;
    },
  });

  return sessionQuery;
};
