import i18n from 'i18next';
import { TimeUtils } from "@edutrackr/shared/utils";
import { SessionDetail } from "../types";
import { I18nCommonKeys } from '@edutrackr/shared/constants';

class SessionDetailPrivateFormatter {

  static realStartTimeLabel(startTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionDetailPrivate.realStartTimeLabel;
    return i18n.t(i18nConfig.key, {
      [i18nConfig.params.startTime]: TimeUtils.formatDateTime(startTime),
      [i18nConfig.params.startTimeDistance]: TimeUtils.formatDistanceToNow(startTime)
    });
  }

  static scheduledStartTimeLabel(scheduledStartTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionDetailPrivate.scheduledStartTimeLabel;
    const label = TimeUtils.formatDateTime(scheduledStartTime);
    if (scheduledStartTime < new Date()) {
      return i18n.t(i18nConfig.beforeScheduleTime.key, {
        [i18nConfig.beforeScheduleTime.params.label]: label
      });
    }
    return i18n.t(i18nConfig.afterScheduleTime.key, {
      [i18nConfig.afterScheduleTime.params.label]: label,
      [i18nConfig.afterScheduleTime.params.scheduledStartTimeDistance]: TimeUtils.formatDistanceToNow(scheduledStartTime)
    });
  }

}

/**
 * Formatter used to display session details.
 */
export class SessionDetailFormatter {

  static sessionTypeLabel(session: SessionDetail): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionDetail.sessionTypeLabel;
    if (!session.isEnabled) {
      return i18n.t(i18nConfig.notEnabled);
    }
    return session.isSessionActive
      ? i18n.t(i18nConfig.active)
      : i18n.t(i18nConfig.scheduled);
  }

  static startTimeLabel(session: SessionDetail): string {
    if (session.isSessionActive) {
      return SessionDetailPrivateFormatter.realStartTimeLabel(new Date(session.startTime));
    }
    if (session.scheduledStartTime) {
      return SessionDetailPrivateFormatter.scheduledStartTimeLabel(new Date(session.scheduledStartTime));
    }
    return i18n.t(I18nCommonKeys.services.common.formatters.sessionNoScheduledTime);
  }

}



class SessionMinimalPrivateFormatter {

  static realStartTimeTooltip(startTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionMinimalPrivate.realStartTimeTooltip;
    return i18n.t(i18nConfig.key, {
      [i18nConfig.params.startTime]: TimeUtils.formatDateTime(startTime)
    });
  }

  static realStartTimeLabel(startTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionMinimalPrivate.realStartTimeLabel;
    return i18n.t(i18nConfig.key, {
      [i18nConfig.params.startTimeDistance]: TimeUtils.formatDistanceToNow(startTime)
    });
  }

  static scheduledStartTimeTooltip(scheduledStartTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionMinimalPrivate.scheduledStartTimeTooltip;
    return i18n.t(i18nConfig.key, {
      [i18nConfig.params.scheduledStartTime]: TimeUtils.formatDateTime(scheduledStartTime)
    });
  }

  static scheduledStartTimeLabel(scheduledStartTime: Date): string {
    const i18nConfig = I18nCommonKeys.services.common.formatters.sessionMinimalPrivate.scheduledStartTimeLabel;
    if (scheduledStartTime < new Date()) {
      return i18n.t(i18nConfig.beforeScheduleTime);
    }
    return i18n.t(i18nConfig.afterScheduleTime.key, {
      [i18nConfig.afterScheduleTime.params.scheduledStartTimeDistance]: TimeUtils.formatDistanceToNow(scheduledStartTime)
    });
  }

}

/**
 * Formatter used to display session summaries (ideal for list views).
 */
export class SessionMinimalFormatter {

  static startTimeTooltip(session: SessionDetail): string {
    if (session.isSessionActive) {
      return SessionMinimalPrivateFormatter.realStartTimeTooltip(new Date(session.startTime));
    }
    if (session.scheduledStartTime) {
      return SessionMinimalPrivateFormatter.scheduledStartTimeTooltip(new Date(session.scheduledStartTime));
    }
    return i18n.t(I18nCommonKeys.services.common.formatters.sessionNoScheduledTime);
  }

  static startTimeLabel(session: SessionDetail): string {
    if (session.isSessionActive) {
      return SessionMinimalPrivateFormatter.realStartTimeLabel(new Date(session.startTime));
    }
    if (session.scheduledStartTime) {
      return SessionMinimalPrivateFormatter.scheduledStartTimeLabel(new Date(session.scheduledStartTime));
    }
    return i18n.t(I18nCommonKeys.services.common.formatters.sessionNoScheduledTime);
  }

}
