import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@edutrackr/shared/utils"

const titleVariants = cva(
  "font-poppins font-medium leading-none tracking-tight",
  {
    variants: {
      type: {
        h1: "text-2xl",
        h2: "text-xl",
        h3: "text-lg",
        h4: "text-base",
        h5: "text-sm",
      }
    },
    defaultVariants: {
      type: "h1"
    }
  }
)

export interface TitleProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof titleVariants> {}

const Title = React.forwardRef<HTMLParagraphElement, TitleProps>(
  ({ type, className, ...props }, ref) => {
    const Component = type ?? "h1"
    return (
      <Component
        ref={ref}
        className={cn(titleVariants({ type }), className)}
        {...props}
      />
    )
  }
);
Title.displayName = "Title"

export { Title }
