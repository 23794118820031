import { BaseService, ChatUtils, HttpClient } from "@edutrackr/shared/utils";
import { BaseApiResponse, MessageRole } from "@edutrackr/shared/types";
import { GetAssistantAnswerRequest, GetAssistantAnswerResponse, GetAssistantChatContentRequest, GetAssistantChatContentResponse, GetLastSessionChatContentRequest, GetLastSessionChatContentResponse, StartNewChatResponse, StartNewCourseChatRequest, StartNewSessionChatRequest, GetLastSessionChatContentPartialResponse, GetCourseChatsRequest, GetChatsResponse, GetSessionChatsRequest } from "./types";

export class AssistantService extends BaseService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  // ### AI ASSISTANT ###

  //#region AI Assistant - Course Learning (Tutor)

  /**
   * Start a new course chat with the AI assistant.
   */
  startNewCourseChat(request: StartNewCourseChatRequest): Promise<BaseApiResponse<StartNewChatResponse>> {
    return this.httpClient.post<BaseApiResponse<StartNewChatResponse>>('/assistant/startNewChat', request);
  }

  /**
   * Get the list of chats for a given course.
   */
  getCourseChats(request: GetCourseChatsRequest): Promise<BaseApiResponse<GetChatsResponse>> {
    return this.httpClient.get<BaseApiResponse<GetChatsResponse>>('/assistant/getUserCourseAssistantChats', request);
  }

  //#endregion

  //#region AI Assistant - Session

  /**
   * Get the list of chats for a given course.
   */
  getSessionChats(request: GetSessionChatsRequest): Promise<BaseApiResponse<GetChatsResponse>> {
    return this.httpClient.get<BaseApiResponse<GetChatsResponse>>('/assistant/getUserSessionAssistantChats', request);
  }

  /**
   * Start a new course session chat with the AI assistant.
   */
  startNewSessionChat(request: StartNewSessionChatRequest): Promise<BaseApiResponse<StartNewChatResponse>> {
    return this.httpClient.post<BaseApiResponse<StartNewChatResponse>>('/assistant/startNewChat', request);
  }

  /**
   * Get the content (messages) of the last chat for a given course session.
   */
  async getLastSessionChatContent(request: GetLastSessionChatContentRequest): Promise<BaseApiResponse<GetLastSessionChatContentResponse>> {
    const lastSessionChatContent = await this.httpClient.get<BaseApiResponse<GetLastSessionChatContentPartialResponse>>('/assistant/getLastUserSessionAssistantChat', request);

    if (!lastSessionChatContent.success) {
      return {
        ...lastSessionChatContent,
        data: undefined,
      } satisfies BaseApiResponse<GetLastSessionChatContentResponse>;
    }

    if (lastSessionChatContent.data && lastSessionChatContent.data.chatSession !== null) {
      return lastSessionChatContent as BaseApiResponse<GetLastSessionChatContentResponse>;
    }

    const newSessionChat = await this.startNewSessionChat({
      sessionId: request.sessionId,
    });
    if (!newSessionChat.success || !newSessionChat.data) {
      return {
        ...newSessionChat,
        data: undefined,
      } satisfies BaseApiResponse<GetLastSessionChatContentResponse>;
    }

    return {
      ...newSessionChat,
      data: {
        chatSession: {
          id: newSessionChat.data.id,
          messages: [],
        },
      },
    } satisfies BaseApiResponse<GetLastSessionChatContentResponse>;
  }

  //#endregion

  //#region AI Assistant - Common

  /**
   * Get the content (messages) of a chat with the AI assistant.
   */
  getAssistantChatContent(request: GetAssistantChatContentRequest): Promise<BaseApiResponse<GetAssistantChatContentResponse>> {
    return this.httpClient.get<BaseApiResponse<GetAssistantChatContentResponse>>('/assistant/getAssistantChat', request);
  }

  /**
   * Send a question to the AI assistant and get a response.
   */
  getAssistantAnswer(request: GetAssistantAnswerRequest): Promise<BaseApiResponse<GetAssistantAnswerResponse>> {
    return this.httpClient.post<BaseApiResponse<GetAssistantAnswerResponse>>('/assistant/getAssistantAnswer', request);
  }

  //#endregion

}

export class DummyAssistantService extends AssistantService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCourseChats(request: GetCourseChatsRequest): Promise<BaseApiResponse<GetChatsResponse>> {
    return Promise.resolve(this.successResponse<GetChatsResponse>("Success", {
      assistantChatSummaries: [
        {
          id: '1',
          title: 'Course Chat 1',
        },
        {
          id: '2',
          title: 'Course Chat 2',
        },
      ],
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startNewCourseChat(request: StartNewCourseChatRequest): Promise<BaseApiResponse<StartNewChatResponse>> {
    return Promise.resolve(this.successResponse<StartNewChatResponse>("Success", { id: '1' }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getSessionChats(request: GetSessionChatsRequest): Promise<BaseApiResponse<GetChatsResponse>> {
    return Promise.resolve(this.successResponse<GetChatsResponse>("Success", {
      assistantChatSummaries: [
        {
          id: '1',
          title: 'Session Chat 1',
        },
        {
          id: '2',
          title: 'Session Chat 2',
        },
      ],
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  startNewSessionChat(request: StartNewSessionChatRequest): Promise<BaseApiResponse<StartNewChatResponse>> {
    return Promise.resolve(this.successResponse<StartNewChatResponse>("Success", { id: '1' }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAssistantChatContent(request: GetAssistantChatContentRequest): Promise<BaseApiResponse<GetAssistantChatContentResponse>> {
    return Promise.resolve(this.successResponse<GetAssistantChatContentResponse>("Success", {
      chatSession: {
        id: '1',
        messages: ChatUtils.getInitialMessages('Hello! How can I help you today?'),
      }
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAssistantAnswer(request: GetAssistantAnswerRequest): Promise<BaseApiResponse<GetAssistantAnswerResponse>> {
    return Promise.resolve(this.successResponse<GetAssistantAnswerResponse>("Success", {
      answer: {
        role: MessageRole.Assistant,
        content: 'I can help you with that. Here is a list of things you can do:\n\n- Create a new session plan\n- Update an existing session plan\n- Get a session plan\n\nWhat would you like to do?',
      },
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getLastSessionChatContent(request: GetLastSessionChatContentRequest): Promise<BaseApiResponse<GetLastSessionChatContentResponse>> {
    return Promise.resolve(this.successResponse<GetLastSessionChatContentResponse>("Success", {
      chatSession: {
        id: '1',
        messages: ChatUtils.getInitialMessages('Hello! How can I help you today?'),
      },
    }));
  }
}
