import { HttpStatusCode } from "axios";
import { HttpErrorParams } from "../types";

export class HttpError extends Error {

  status: HttpStatusCode;
  data?: unknown;
  originalError?: unknown;

  constructor(data: HttpErrorParams) {
    super(data.message);
    this.name = "HttpError";
    this.status = data.status;
    this.data = data.data;
    this.originalError = data.originalError;
  }

}
