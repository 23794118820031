export class StringUtils {
  static getFirstLetter(str: string) {
    return str.charAt(0).toUpperCase();
  }

  static getFirstWord(str: string) {
    return str.split(" ")[0];
  }

  static getInitials(str: string) {
    const words = str.split(" ");
    return words.map(word => StringUtils.getFirstLetter(word)).join("");
  }
}
