import i18n from "i18next";
import { ErrorUtils } from '@edutrackr/shared/utils';
import { QueryCache, QueryClientConfig } from '@tanstack/react-query';
import { toast } from 'sonner';
import { I18nCommonKeys } from "@edutrackr/shared/constants";

export const DefaultQueryConfig: QueryClientConfig = {
  defaultOptions: {},
  queryCache: new QueryCache({
    onError: error => {
      const errorDetail = ErrorUtils.getErrorDetail(error);
      toast.error(i18n.t(I18nCommonKeys.messages.failedQuery), {
        description: errorDetail.message
      });
    },
  }),
};
